<template>
  <div class="rich-text">
    <p class="p1">
      <span class="s1"><strong><br/></strong></span>
    </p>
    <p class="p1" style="text-align: center;">
      <span style="font-size: 18px;"><strong>使用条款（企业及团体）</strong></span>
    </p>
    <p class="p1">
      <span class="s1"><strong><br/></strong></span>
    </p>
    <p class="p2">
      <span class="s2"><strong>1. </strong></span><span class="s1"><strong>你的协议</strong></span>
    </p>
    <p class="p2">
      <span class="s1"><strong><br/></strong></span>
    </p>
    <p class="p3">
      <span class="s2">1.1 </span><span class="s3">此网站（</span><span class="s2">“www.unutravel.com”</span><span
      class="s3">）由香港注册公司</span><span class="s2">UNU TECHNOLOGY LIMITED</span><span class="s3">营运。使用此网站及由</span><span
      class="s2">UNU TECHNOLOGY LIMITED</span><span class="s3">及其关联公司（合称</span><span class="s2">&quot;</span><span
      class="s3">一游</span><span class="s2"> UNU TRAVEL&quot;</span><span class="s3">）或第三方营运者（</span><span
      class="s2">“</span><span class="s3">营运者</span><span class="s2">”</span><span class="s3">）透过此网站所提供的服务（</span><span
      class="s2">“</span><span class="s3">服务</span><span class="s2">”</span><span class="s3">）前请先细阅条款（</span><span
      class="s2">“</span><span class="s3">本《使用条款》</span><span class="s2">”</span><span class="s3">）。此条款中使用的</span><span
      class="s2">“</span><span class="s3">你</span><span class="s2">”</span><span class="s3">及</span><span
      class="s2">“</span><span class="s3">你的</span><span class="s2">”</span><span class="s3">包括（</span><span
      class="s2">1</span><span class="s3">）任何进入此网站的人及（</span><span class="s2">2</span><span
      class="s3">）任何透过你购买服务的人。</span>
    </p>
    <p class="p3">
      <span class="s3"><br/></span>
    </p>
    <p class="p2">
      <span class="s2"><strong>2.</strong></span><span class="s1"><strong>《使用条款》变更</strong></span>
    </p>
    <p class="p2">
      <span class="s1"><strong><br/></strong></span>
    </p>
    <p class="p4">
      <span class="s3">2.1 </span><span class="s1">一游</span><span class="s3"> UNU TRAVEL</span><span
      class="s1">修订</span>
    </p>
    <p class="p4">
      <span class="s2"><br/></span>
    </p>
    <p class="p4">
      <span class="s2">2.1.1 </span><span class="s3">一游</span><span class="s2"> UNU TRAVEL</span><span
      class="s3">按本公司的绝对酌情权可随时及不定时修改或更新本《使用条款》，且无须提前通知。你应定期查阅最新版本的《使用条款》所述之约束。如果一游</span><span
      class="s2"> UNU TRAVEL</span><span class="s3">修改或更新本《使用条款》，一游</span><span class="s2"> UNU TRAVEL</span><span
      class="s3">会将变更或修正的内容张贴在此网页上，并在此网页的最下方指出本《使用条款》最近一次修订的日期。</span>
    </p>
    <p class="p4">
      <span class="s2"><br/></span>
    </p>
    <p class="p4">
      <span class="s2">2.1.2 </span><span
      class="s3">若你于该等更改后继续使用本网站，将意味阁下同意并接纳经修订的《使用条款》。假若你不同意本《使用条款》的任何修订，请立即停止使用或存取本网站及服务。你有责任定期查阅本网站，以审查及确认本《使用条款》之任何变更。</span>
    </p>
    <p class="p4">
      <span class="s2"><br/></span>
    </p>
    <p class="p4">
      <span class="s2">2.1.3 </span><span class="s3">此外，在使用服务时，你应接受任何不定时更新的所有与服务相关的额外条款约束，以及一游</span><span
      class="s2"> UNU TRAVEL</span><span class="s3">不时采纳的隐私政策（下称《隐私保护政策》）。本公司在此明示，所有此等条款被提及时，均被纳入本《使用条款》。</span>
    </p>
    <p class="p2">
      <span class="s2"><strong>3 </strong></span><span class="s1"><strong>登入及使用服务</strong></span>
    </p>
    <p class="p2">
      <span class="s1"><strong><br/></strong></span>
    </p>
    <p class="p3">
      <span class="s2">3.1 </span><span class="s3">内容拥有权</span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">3.1.1 </span><span class="s3">此网站、域名（</span><span class="s2"> www.unutravel.com </span><span
      class="s3">）、子域、技术功能、数据内容及应用服务（包括但不限于行动应用服务）或与此相关由一游</span><span class="s2"> UNU TRAVEL</span><span
      class="s3">定期提供的服务，一律由一游</span><span class="s2"> UNU TRAVEL</span><span class="s3">所拥有和经营。</span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">3.2 </span><span class="s3">存取服务之权责</span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">3.2.1 </span><span class="s3">在本《使用条款》的规范下，一游</span><span class="s2"> UNU TRAVEL</span><span
      class="s3">可自行或代表其他营运业者提供服务，此等服务在本网站上有更完整的说明。你在本网站上选取的服务只限你个人使用，不得供给任何第三方使用或获取任何利益。「服务」一词包括但不限于使用本网站，与及使用一游</span><span
      class="s2"> UNU TRAVEL</span><span class="s3">自行或代表其他业者在本网站上提供的任何服务。一游</span><span
      class="s2"> UNU TRAVEL</span><span class="s3">有权不定期更改、暂停或终止任何服务，包括任何技术功能、数据存库或内容讯息。一游</span><span
      class="s2"> UNU TRAVEL</span><span class="s3">亦有权对个别服务加上规范、修订条款，或限制你存取某部分或全部的服务，而无需提前通知。</span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">3.2.2 </span><span class="s3">一游</span><span class="s2"> UNU TRAVEL</span><span
      class="s3">不保证其服务能时刻运作或不受外在因素影响。一游</span><span class="s2"> UNU TRAVEL</span><span
      class="s3">将不会对你于任何时间就无法运作之服务负责。你有责任作出所有必要的安排以存取服务，及确保透过网络联机而存取服务的所有人都了解本《使用条款》和其他与服务有关可适用条款与细则。</span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">3.2.3</span><span class="s3">当你连结到本网站，一游</span><span class="s2"> UNU TRAVEL</span><span
      class="s3">有绝对酌情权决定撤消你在任何时候连结到本网站的权利。一游</span><span class="s2"> UNU TRAVEL</span><span
      class="s3">亦有权要求你必须先获得书面许可后，才能连结到本网站。</span>
    </p>
    <p class="p2">
      <span class="s2"><strong><br/></strong></span>
    </p>
    <p class="p2">
      <span class="s2"><strong>4. </strong></span><span class="s1"><strong>网站与内容</strong></span>
    </p>
    <p class="p2">
      <span class="s1"><strong><br/></strong></span>
    </p>
    <p class="p3">
      <span class="s2">4.1 </span><span class="s3">内容的使用</span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">4.1.1 </span><span
      class="s3">本网站采用或呈现的所有数据，包括但不限于文字、数据、图像、文章、照片、影像、插图、影片、声效及其他素材及数据（下统称为「内容」），皆受著作权及</span><span
      class="s2"> / </span><span class="s3">或知识产权的保护。本网站所有内容一律只限你个人且</span><span class="s5"> </span><span
      class="s3">非商业性质使用的服务，并必须根据本《使用条款》之规范使用。</span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">4.1.2 </span><span class="s3">当一游</span><span class="s2"> UNU TRAVEL</span><span
      class="s3">同意授权你存取本网站及</span><span class="s2">/</span><span
      class="s3">或网站内容，此等授权应为非专属、不得移转，并只限根据本《使用条款》之规范而执行相关存取。一游</span><span class="s2"> UNU TRAVEL</span><span
      class="s3">有绝对酌情权可随时修订、移除或变更本网站部分或所有内容的呈现、本质或功能，而无须提前通知。</span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">4.1.3 </span><span
      class="s3">你必须遵守本网站及透过本网站存取的内容所包含的所有著作权公告、商标规范、相关信息权限，且在未获得个别持有人的事先书面同意前，你不得以违反第三方权利的方式，以任何理由或目的使用、复制、重制、修改、翻译、刊载、传播、传输、发放、执行、上载、呈现、授权、出售或以其他方式利用本网站、网站内容、第三方提交之数据，或其他非你所拥有的专属权利。</span>
    </p>
    <p class="p3">
      <span class="s3"><br/></span>
    </p>
    <p class="p4">
      <span class="s3">4.2 </span><span class="s1">一游</span><span class="s3"> UNU TRAVEL</span><span
      class="s1">对网站及内容之权责</span>
    </p>
    <p class="p4">
      <span class="s2"><br/></span>
    </p>
    <p class="p4">
      <span class="s2">4.2.1 </span><span class="s3">一游</span><span class="s2"> UNU TRAVEL</span><span
      class="s3">无法保证你在使用本网站的过程中，可能与你互动的任何其他使用者为何人。一游</span><span class="s2"> UNU TRAVEL</span><span
      class="s3">亦无法保证其他使用者或其他业者可能提供的任何内容、数据或信息是否真实且正确。你须对使用本网站存取任何内容自行承担风险，且若任何人因你的使用而产生任何损害或损失，皆必须由你个人单独承担责任。</span>
    </p>
    <p class="p4">
      <span class="s2"><br/></span>
    </p>
    <p class="p4">
      <span class="s2">4.2.2 </span><span class="s3">一游</span><span class="s2"> UNU TRAVEL</span><span
      class="s3">在任何情况下皆无须对任何内容负责，包括但不限于内容的错误或疏漏，或因使用、公布、使用电子邮件传送、存取、传输网站及网站的任何内容而产生的任何损害或损失。</span>
    </p>
    <p class="p2">
      <span class="s2"><strong><br/></strong></span>
    </p>
    <p class="p2">
      <span class="s2"><strong>5. </strong></span><span class="s1"><strong>知识财产权利</strong></span>
    </p>
    <p class="p2">
      <span class="s1"><strong><br/></strong></span>
    </p>
    <p class="p4">
      <span class="s3">5.1 </span><span class="s1">知识财产</span>
    </p>
    <p class="p4">
      <span class="s2"><br/></span>
    </p>
    <p class="p4">
      <span class="s2">5.1.1 </span><span class="s3">与本网站有关的所有知识产权皆属于一游</span><span class="s2"> UNU TRAVEL</span><span
      class="s3">，或已授权予一游</span><span class="s2"> UNU TRAVEL</span><span
      class="s3">用于本网站。根据可适用的著作权法、国际公约及其他相关的知识产权法律，本网站、服务及内容为集体作品及</span><span class="s2"> / </span><span
      class="s3">或编辑内容，应受著作权及其他知识产权法所保护。你承诺：（</span><span class="s2">a</span><span
      class="s3">）你不得对本网站之任何内容、软件、数据或服务进行全部或部分修正、刊载、传输、参与转移或出售、重制、以之为基础创造衍生作品、发放、执行、呈现或以任何方式利用。（</span><span
      class="s2">b</span><span class="s3">）你仅限为个人且非商业性质之用途而下载或复制内容</span><span class="s2"> (</span><span
      class="s3">以及呈现于本网站或与服务相关的其他项目</span><span class="s2">)</span><span
      class="s3">，并确保你已遵守此等内容所含的所有著作权及其他条款公告。（</span><span class="s2">c</span><span
      class="s3">）你不得以任何形式储存相当大部分的任何内容。除个人非商业性质之用途外，在未获得一游</span><span class="s2"> UNU TRAVEL</span><span
      class="s3">或著作权公告中所指明的著作权持有人之事前书面允许前，本公司明确地禁止你复制或储存任何内容。</span>
    </p>
    <p class="p4">
      <span class="s2"><br/></span>
    </p>
    <p class="p4">
      <span class="s2">5.1.2</span><span class="s3">一游</span><span class="s2"> UNU TRAVEL</span><span
      class="s3">及你及其职员承诺在履行本协议期间及在本协议期满后不对对方所拥有的商标、企业名称、域名等进行贬低或者其它任何损害，也不对对方互联网网页或者网站进行任何贬低、抄袭、歪曲、破坏或其它损害。</span>
    </p>
    <p class="p4">
      <span class="s2"><br/></span>
    </p>
    <p class="p4">
      <span class="s2">5.1.3 </span><span class="s3">本协议有效期内及本协议终止或者解除后，一游</span><span
      class="s2"> UNU TRAVEL</span><span
      class="s3">及你及其职员承诺不向任何非本协议签署方（包括但不限于与对方构成商业竞争关系的企业、商业机构或者组织）提供有关双方业务、技术等一切相关信息或者数据，否则由一方承担相应的责任，并赔偿另一方因此造成的一切损失。</span>
    </p>
    <p class="p2">
      <span class="s2"><strong><br/></strong></span>
    </p>
    <p class="p2">
      <span class="s2"><strong>6. </strong></span><span class="s1"><strong>用户提交之数据</strong></span>
    </p>
    <p class="p4">
      <span class="s3"><br/></span>
    </p>
    <p class="p4">
      <span class="s3">6.1 </span><span class="s1">上载资料</span>
    </p>
    <p class="p4">
      <span class="s2"><br/></span>
    </p>
    <p class="p4">
      <span class="s2">6.1.1 </span><span class="s3">在存取本网站或使用服务时，你提供的信息可能会被一游</span><span class="s2"> UNU TRAVEL</span><span
      class="s3">及</span><span class="s2">/</span><span
      class="s3">或与服务相关其他业者采用，而本网站的其他使用者将可能看到这些信息。当你将信息或内容张贴于本网站，或以其他方式将内容、数据或信息（下统称「用户提交之数据」）提供给一游</span><span
      class="s2"> UNU TRAVEL</span><span class="s3">及</span><span class="s2">/</span><span
      class="s3">或与服务相关的其他业者时，即表示：（</span><span class="s2">a</span><span class="s3">）你授权一游</span><span
      class="s2"> UNU TRAVEL</span><span class="s3">及其他业者在提供服务及经营本网站和一游</span><span class="s2"> UNU TRAVEL</span><span
      class="s3">的业务时，使用部份或全部此等用户提交之数据（包括此等数据的所有相关知识产权），包括但不限于使用任何媒体形式及透过任何媒体管道推广和重新分配部分或所有服务（及衍生的作品），前述之授权为非专属、可用于全球、免权利费、永久、不得撤销、可转授权且可移转之权利；（</span><span
      class="s2">b</span><span class="s3">）你同意并授权一游</span><span class="s2"> UNU TRAVEL</span><span
      class="s3">根据不定时生效的《隐私保护政策》来使用你的个人资料；（</span><span class="s2">c</span><span
      class="s3">）你也在此授权本网站的每个用户透过本网站的功能，在本《使用条款》允许的范围内，在本网站上存取你的用户提交之数据，并使用、修正、重制、发放、编制衍生作品、呈现和执行此等用户提交之数据，前述之授权为非专属之权利；（</span><span
      class="s2">d</span><span class="s3">）你在此确认且同意，一游</span><span class="s2"> UNU TRAVEL</span><span
      class="s3">有权重新编排、修正、创造衍生作品、摘录和翻译你的任何用户提交之数据。为厘清疑义，除非另有书面议定，前述授予一游</span><span class="s2"> UNU TRAVEL</span><span
      class="s3">之权利不会影响你对用户提交之数据的所有权，或授权他人使用此等数据的权利；以及（</span><span class="s2">e</span><span
      class="s3">）你仅此声明及保证你提交的用户内容（包括、但不限于：文字、图像及相片）没有违反或损害任何现行法律、规章或第三方权益；以及（</span><span class="s2">f</span><span
      class="s3">）你必须独自负责在本网站上公开张贴或透过本网站私下传输的所有用户提交之数据，一游</span><span class="s2"> UNU TRAVEL</span><span
      class="s3">不对任何内容的错误或疏漏负上任何责任。</span>
    </p>
    <p class="p2">
      <span class="s2"><strong><br/></strong></span>
    </p>
    <p class="p2">
      <span class="s2"><strong>7. </strong></span><span class="s1"><strong>使用者的声明、保证及承诺</strong></span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">7.1 </span><span class="s3">使用本网站及服务</span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">7.1.1 </span><span class="s3">你向一游</span><span class="s2"> UNU TRAVEL</span><span
      class="s3">声明、保证及承诺不会以下述方式向第三方提供任何用户的数据，或以此等方式使用本网站或服务：（</span><span class="s2">a</span><span
      class="s3">）侵害或违反任何第三方的知识产权或专属权、公开权或隐私权或其他权利；（</span><span class="s2">b</span><span
      class="s3">）违反任何规章法令；（</span><span class="s2">c</span><span
      class="s3">）有害、欺诈、虚假、威胁、滥用、骚扰、侵害、诽谤、下流、淫秽、猥亵，或其他令人难以接受的方式；（</span><span class="s2">d</span><span
      class="s3">）未获得一游</span><span class="s2"> UNU TRAVEL</span><span class="s3">的事前以书面同意而参与商业性活动及</span><span
      class="s2">/</span><span class="s3">或销售，例如竞赛、博弈、以物易物、广告或层压式推销；（</span><span class="s2">e</span><span
      class="s3">）诽谤或假冒任何人或法律实体，包括但不限于一游</span><span class="s2"> UNU TRAVEL</span><span
      class="s3">的任何员工或代表人；或（</span><span class="s2">f</span><span
      class="s3">）包含病毒程序、特洛伊木马程序、蠕虫程序、定时炸弹程序，或其他有害的计算机程序码、档案或程序。</span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">7.2 </span><span class="s3">移除用户提交之数据</span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">7.2.1 </span><span class="s3">一游</span><span class="s2"> UNU TRAVEL</span><span
      class="s3">有权基于任何理由（包括但不限于收到第三方或主管机关有关此等用户提交之数据的主张或指控，或一游</span><span class="s2"> UNU TRAVEL</span><span
      class="s3">怀疑你可能已违反任何先前的声明、保证或承诺）或不具任何理由，随时将任何用户提交之数据从本网站移除。</span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">7.3 </span><span class="s3">用户提交数据之责任</span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">7.3.1 </span><span
      class="s3">你必须独自对由你所上载、张贴、使用电子邮件传送、传输，或以其他方式发放，或连接与本网站有关的所有用户提交之数据负上全部责任。</span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">7.3.2 </span><span class="s3">你在此声明且同意，你须独自为所提交的个人用户数据张贴或刊载于本网站负责。你且向一游</span><span
      class="s2"> UNU TRAVEL</span><span class="s3">声明、保证及承诺：（</span><span class="s2">a</span><span
      class="s3">）你拥有或具有确实的权利、授权、同意、豁免及</span><span class="s2">/</span><span class="s3">或允许，可使用并授权一游</span><span
      class="s2"> UNU TRAVEL</span><span
      class="s3">使用任何用户提交之数据的所有著作权、商标或其他专属或智识产权，因此本网站及本《使用条款》可纳入和使用此等数据；以及（</span><span class="s2">b</span><span
      class="s3">）用户提交之数据以及你张贴、上载、刊载、提交或传输此等数据，或一游</span><span class="s2"> UNU TRAVEL</span><span
      class="s3">在本网站上或透过本网站提供的服务来使用此等数据（或其任何部分），均未侵犯、盗用或违反任何第三方的专利、著作权、商标、商业机密、道德权利、其他专属知识产权，或者其他公开权或隐私权，且未违反任何可适用的法令规章。</span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">7.3.3 </span><span class="s3">你必须对使用本网站及</span><span class="s2">/</span><span
      class="s3">或服务的所有相关活动负上全部责任。你且进一步向客户声明、保证及承诺，你不应：（</span><span class="s2">a</span><span
      class="s3">）从事任何诈欺、滥用或其他不合法的活动，若你从事此等活动，我们可能终止你存取或使用本网站及</span><span class="s2">/</span><span
      class="s3">或服务的权利；或（</span><span class="s2">b</span><span
      class="s3">）出售或转售从本网站或移动应用程序或通过本网站或移动应用程序获得的任何产品，服务或预订</span><span class="s2">; </span><span
      class="s3">（</span><span class="s2">c</span><span
      class="s3">）将本网站或移动应用程序用于商业或竞争活动或目的，或用于进行投机，虚假或欺诈性预订或预期需求的任何预订</span><span class="s2">; </span><span
      class="s3">或（</span><span class="s2">d</span><span
      class="s3">）张贴或传输，或利用他人张贴或传输特别设计或故意的任何通讯或诱导内容；为取得本网站其他用户的密码、帐户或私人数据，或（</span><span class="s2">e</span><span
      class="s3">）违反任何计算机网络的安全规定、破解密码或安全加密码、移转或储存不合法的数据（包括可能被视为具有威胁性或猥亵的数据），或从事任何被明白禁止的违法活动；（</span><span
      class="s2">f</span><span class="s3">）在本网站上执行邮件群组（</span><span class="s2">mail list</span><span
      class="s3">）、列表伺服（</span><span class="s2">listserv</span><span
      class="s3">）或任何其他形式的自动回复程序或垃圾邮件，或在你未登入本网站的状态下执行或启动任何流程，或以其他方式干扰本网站基础设备的适当运作，或对本网站基建加诸不合理的负担；或（</span><span
      class="s2">g</span><span class="s3">）使用手动或自动的软件、装置或其他流程，对本网站的任何网页进行「爬梳」（</span><span class="s2">crawl</span><span
      class="s3">）、「抓取」（</span><span class="s2">scrape</span><span class="s3">）或「使用搜索引擎蜘蛛」（</span><span
      class="s2">spider</span><span class="s3">）；以及（</span><span class="s2">h</span><span
      class="s3">）对本网站的原始码进行反译、反向工程，或以其他方式试图取得本网站的原始码。</span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">7.3.4 </span><span class="s3">你将自行负责保存、记录，及申报任何你透过本网站及</span><span class="s2">/</span><span
      class="s3">或服务所提供的活动之相关税项、责任及政府所需征收之费用。</span>
    </p>
    <p class="p3">
      <span class="s3"><br/></span>
    </p>
    <p class="p2">
      <span class="s2"><strong>8. </strong></span><span class="s1"><strong>注册账户与安全保障</strong></span>
    </p>
    <p class="p2">
      <span class="s1"><strong><br/></strong></span>
    </p>
    <p class="p4">
      <span class="s3">8.1 </span><span class="s1">开设一游</span><span class="s3"> UNU TRAVEL</span><span
      class="s1">账户</span>
    </p>
    <p class="p4">
      <span class="s2"><br/></span>
    </p>
    <p class="p4">
      <span class="s2">8.1.1 </span><span class="s3">在使用服务的过程中，你可能需应要求在本一游</span><span
      class="s2"> UNU TRAVEL</span><span class="s3">网站上开设并维持一个一游</span><span class="s2"> UNU TRAVEL</span><span
      class="s3">账户（下称「一游</span><span class="s2"> UNU TRAVEL</span><span class="s3">账户」）。</span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">8.2 </span><span class="s3">提供个人资料</span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">8.2.1 </span><span class="s3">基于部份服务的使用条款，你可能需应要求在一游</span><span
      class="s2"> UNU TRAVEL</span><span class="s3">上登记及设定密码和用户名称（一游</span><span class="s2"> UNU TRAVEL</span><span
      class="s3">用户</span><span class="s2">ID</span><span class="s3">）。如果你透过第三方网站或服务登入，一游</span><span
      class="s2"> UNU TRAVEL</span><span class="s3">将可能要求你的一游</span><span class="s2"> UNU TRAVEL</span><span
      class="s3">用户</span><span class="s2">ID</span><span class="s3">与你于第三方网站或服务的用户名称一致。</span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">8.2.2 </span><span class="s3">你必须向一游</span><span class="s2"> UNU TRAVEL</span><span
      class="s3">提供准确、完全及最新的用户数据。若未有如实提供将被视作违反此使用条款，而有可能被实时终止使用一游</span><span class="s2"> UNU TRAVEL</span><span
      class="s3">账户的权利。</span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">8.2.3 </span><span class="s3">你声明，你绝不应该：（</span><span class="s2">a</span><span
      class="s3">）选择或使用其他人的一游</span><span class="s2"> UNU TRAVEL</span><span class="s3">用户</span><span
      class="s2">ID</span><span class="s3">以图模仿该人；或（</span><span class="s2">b</span><span
      class="s3">）未经授权下使用非他人之一游</span><span class="s2"> UNU TRAVEL</span><span class="s3">用户</span><span
      class="s2">ID</span><span class="s3">。</span>
    </p>
    <p class="p3">
      <span class="s3"><br/></span>
    </p>
    <p class="p3">
      <span class="s3">8.2.4 </span><span class="s1">一游</span><span class="s3"> UNU TRAVEL</span><span
      class="s1">保留拒绝注册或取消一游</span><span class="s3"> UNU TRAVEL</span><span class="s1">账户的绝对酌情权。</span>
    </p>
    <p class="p8">
      <span class="s2"><strong><br/></strong></span>
    </p>
    <p class="p8">
      <span class="s2"><strong>9. </strong></span><span class="s3"><strong>评价</strong></span><span
      class="s2"><strong> – </strong></span><span class="s3"><strong>进一步对应</strong></span><span
      class="s2"><strong> – </strong></span><span class="s3"><strong>使用用户内容之权利</strong></span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">9.1 </span><span class="s3">为完成订单，你同意接收预订确认通知（电子邮件与</span><span class="s2">/</span><span
      class="s3">或</span><span class="s2">app</span><span class="s3">应用程序通知），以及在完成活动订单后将会收到邀请填写活动评论的电子邮件或</span><span
      class="s2">app</span><span
      class="s3">应用程序通知。你可自由决定是否填写。确认订单通知及活动评论邀请皆是预订流程的一部份，它们并不属于广告通讯或市场推广的一部份。已完成的用户活动评论会在</span><span
      class="s2">72</span><span class="s3">小时内上传至一游</span><span class="s2"> UNU TRAVEL</span><span
      class="s3">平台上的相关活动页面，以告知（未来）用户你对于此活动的服务（水平）和质量的意见。</span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">9.2 </span><span class="s3">透过公布评论，表明你授予一游</span><span class="s2"> UNU TRAVEL</span><span
      class="s3">对所有用户内容完整、永久、免费、可转让及不可撤销的权利。一游</span><span class="s2"> UNU TRAVEL</span><span
      class="s3">有绝对酌情权自行决定对相关内容进行编辑、调整、拒绝采用或删除的权利。</span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">9.3 </span><span class="s3">你确认将遵守用户评论规范，并声明、保证：</span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">9.3.1 </span><span class="s3">你拥有及有权控制所公布或发放的用户内容，或你具有透过平台公布或发放用户内容的合法权利；</span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">9.3.2 </span><span class="s3">该内容准确并无误导性；及</span>对该内容的使用、公布或其他传播行为没有违反使用条款或任何相应法律及规例，且不会违反任何个人或机构的权利，及不会对其造成任何伤害。
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">9.4 </span><span class="s3">评论不可含有抵毁、污言秽语、不庄重、威胁及辱骂、鼓吹违法行为，亦不可含有其他人的个人资料如名字，电话</span>号码或电子邮箱，及不相关内容如推广讯息、邀请及奖赏数据。此外，评论不可含有诽谤、侵犯性，或违反其他的法律权利的内容。
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">9.5 </span><span class="s3">你且授予一游</span><span class="s2"> UNU TRAVEL</span><span
      class="s3">向侵犯你或一游</span><span class="s2"> UNU TRAVEL</span><span
      class="s3">的权利及违反使用条款的人或机构保持法律追诉权利。你并同意独自对所提供的用户内容负上全责。</span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">9.6 </span><span class="s3">由用户所提交的内容将被视为非机密信息，一游</span><span class="s2"> UNU TRAVEL</span><span
      class="s3">亦没有责任视之为专属数据。除了以上所述，一游</span><span class="s2"> UNU TRAVEL</span><span
      class="s3">则保留权利可适当使用该内容，包括及不限于，删除、编辑、更改、不接受，或拒绝公布。对你提交的内容，一游</span><span class="s2"> UNU TRAVEL</span><span
      class="s3">无需向你支付任何费用，亦无需为你提供编辑、删除或更改已公布的内容的机会。一游</span><span class="s2"> UNU TRAVEL</span><span
      class="s3">没有责任授予你该内容的署名权，也没有责任执行第三方的署名权。请参考平台上的使用条款以了解更多。</span>
    </p>
    <p class="p2">
      <span class="s2"><strong><br/></strong></span>
    </p>
    <p class="p2">
      <span class="s2"><strong>10. </strong></span><span class="s1"><strong>订单确认、票券、凭证、费用及付款</strong></span>
    </p>
    <p class="p4">
      <span class="s3"><br/></span>
    </p>
    <p class="p4">
      <span class="s3">10.1</span><span class="s1">订单确认</span>
    </p>
    <p class="p4">
      <span class="s2"><br/></span>
    </p>
    <p class="p4">
      <span class="s2">10.1.1 </span><span
      class="s3">部分指定活动或服务接受实时确认。除此类活动或服务外，任何在本网站所述的确认时间仅供参考。实际所需的确认时间，会因应情况而有所调整。</span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">10.2 </span><span class="s3">购买及使用凭证</span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">10.2.1 </span><span
      class="s3">透过本网站，你可订购由目的地供货商所提供的服务的电子凭证（下称「凭证」）。在相关供货商的条款限制下，你将收到一封确认你订单的电子邮件，其中包含凭证的确认号码（下称「确认号码」），以及可打印的版本。</span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">10.2.2 </span><span class="s3">使用你的凭证时，你须准时到达相关供货商指定的集合地点，且提供该供货商要求的文件及</span><span
      class="s2">/</span><span class="s3">或数据，例如你的预订确认号码及</span><span class="s2">/</span><span
      class="s3">或打印的凭证。若你无法准时出现，或未能提供必要的文件或数据，你将不会享受该服务或退款。</span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">10.2.3 </span><span class="s3">供货商可能要求你提供载有你照片的身份证明文件，才准许你使用凭证。若你遗失凭证、或凭证被破坏、遭偷窃，一游</span><span
      class="s2"> UNU TRAVEL</span><span
      class="s3">或供货商均无需负上责任。此外，若法律禁止提供相关服务，凭证将失效。若你试图以不合法的形式兑换凭证（比方说你欲参加品酒活动，但未达能使用该服务的法定年龄），相关供货商将可拒绝接受你的凭证，且无需安排退款。</span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">10.3 </span><span class="s3">凭证之《使用条款》</span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">10.3.1 </span><span
      class="s3">每项凭证的《使用条款》可能因不同供货商而异，当你于网站进行订购时，将被告知适用于凭证的任何限制（包括但不限于最低年龄要求）。</span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">10.3.2 </span><span
      class="s3">凭证是一次项目（下称「项目」）的入场券；凭证上会载明可使用的期限。除非凭证上另有明白规定，否则若你未能在凭证上载明的期限内使用凭证，将不获退款。</span>
    </p>
    <p class="p4">
      <span class="s3"><br/></span>
    </p>
    <p class="p4">
      <span class="s3">10.4 </span><span class="s1">取消凭证</span>
    </p>
    <p class="p4">
      <span class="s2"><br/></span>
    </p>
    <p class="p4">
      <span class="s2">10.4.1 </span><span class="s3">你可在订购时在网站上载明的取消期限内，联络一游</span><span
      class="s2"> UNU TRAVEL</span><span
      class="s3">的顾客服务，取消你的凭证。取消的联络窗口因个案而异。在成功取消使用凭证且收到必要的通知后，金额将会全数退款至你用来订购该凭证的信用卡。</span>
    </p>
    <p class="p4">
      <span class="s2"><br/></span>
    </p>
    <p class="p4">
      <span class="s2">10.4.2 </span><span class="s3">提供服务该项目的是供货商，而非一游</span><span class="s2"> UNU TRAVEL</span><span
      class="s3">，因此该项目的供货商应独自接受或拒绝你订购的任何凭证，并负责提供所有相关的服务。</span>
    </p>
    <p class="p4">
      <span class="s2"><br/></span>
    </p>
    <p class="p4">
      <span class="s2">10.4.3 </span><span
      class="s3">若你对该项凭证的服务有任何疑问或投诉，请向相关供货商查询。所有凭证已支付的费用均不可退款，除非本《使用条款》明确规定。凭证是以订购当日在网站上载明的货币来报价。</span>
    </p>
    <p class="p4">
      <span class="s2"><br/></span>
    </p>
    <p class="p4">
      <span class="s2">10.4.4 </span><span class="s3">若你所订购的项目被相关供货商取消，一游</span><span class="s2"> UNU TRAVEL</span><span
      class="s3">会尽快以合理的方法通知你，并会将金额全数退款到你用来订购该凭证的信用卡。</span>
    </p>
    <p class="p4">
      <span class="s3"><br/></span>
    </p>
    <p class="p4">
      <span class="s3">10.5 </span><span class="s1">要求协助</span>
    </p>
    <p class="p4">
      <span class="s2"><br/></span>
    </p>
    <p class="p4">
      <span class="s2">10.5.1 </span><span
      class="s3">若你试图根据本《使用条款》及适用于该凭证的相关使用条款来兑换使用凭证，但你无法成功使用（因为供货商的错误或其他原因），请透过电子邮箱</span><span
      class="s2">support@unutravel.com</span><span class="s3">联络一游</span><span class="s2"> UNU TRAVEL</span><span
      class="s3">，一游</span><span class="s2"> UNU TRAVEL</span><span class="s3">将尝试接触并与供货商联系以寻求协商。</span>
    </p>
    <p class="p4">
      <span class="s3"><br/></span>
    </p>
    <p class="p4">
      <span class="s3">10.6 </span><span class="s1">额外收费</span>
    </p>
    <p class="p4">
      <span class="s2"><br/></span>
    </p>
    <p class="p4">
      <span class="s2">10.6.1 </span><span class="s3">一游</span><span class="s2"> UNU TRAVEL</span><span
      class="s3">保留权利以为所提供的任何服务向你提出付款要求或收费。你应依照本网站的说明，支付你选择的服务所需的所有适用的费用。</span>
    </p>
    <p class="p4">
      <span class="s3"><br/></span>
    </p>
    <p class="p4">
      <span class="s3">10.7 </span><span class="s1">收费更改</span>
    </p>
    <p class="p4">
      <span class="s2"><br/></span>
    </p>
    <p class="p4">
      <span class="s2">10.7.1 </span><span class="s3">一游</span><span class="s2"> UNU TRAVEL</span><span
      class="s3">保留随时变更价格及收费的权利，变更的内容可能透过电子邮件或张贴在网站，若你于该等变更的通知发出后使用，或继续使用该服务，意味阁下同意并接纳变更后的价格及收。</span>
    </p>
    <p class="p4">
      <span class="s3"><br/></span>
    </p>
    <p class="p4">
      <span class="s3">10.8 </span><span class="s1">一游</span><span class="s3"> UNU TRAVEL</span><span
      class="s1">的权利及义务</span>
    </p>
    <p class="p4">
      <span class="s2"><br/></span>
    </p>
    <p class="p4">
      <span class="s2">10.8.1 </span><span class="s3">一游</span><span class="s2"> UNU TRAVEL</span><span
      class="s3">保留绝对酌情权以拒绝或取消任何可能违反此条款的服务订单或订购。</span>
    </p>
    <p class="p4">
      <span class="s2"><br/></span>
    </p>
    <p class="p4">
      <span class="s2">10.8.2 </span><span class="s3">一游</span><span class="s2"> UNU TRAVEL</span><span
      class="s3">致力提供或向供货商争取以市场上的最优价值提供服务。你确认了解及认同任何于使用个别服务时所产生的税项或额外费用，皆明确地排除在最优价格计算之外。</span>
    </p>
    <p class="p4">
      <span class="s2"><br/></span>
    </p>
    <p class="p4">
      <span class="s2">10.8.3 </span><span class="s3">尽管供货商被要求向一游</span><span class="s2"> UNU TRAVEL</span><span
      class="s3">网站上之服务提供准确及最新的价格，一游</span><span class="s2"> UNU TRAVEL</span><span
      class="s3">无法保证所提供的服务于任何时候皆准确无误。</span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">10.9 </span><span class="s3">以上条款及细则及退款政策适用于全球的一游</span><span class="s2"> UNU TRAVEL</span><span
      class="s3">用户。</span>
    </p>
    <p class="p2">
      <span class="s2"><strong><br/></strong></span>
    </p>
    <p class="p2">
      <span class="s2"><strong>11. </strong></span><span class="s1"><strong>隐私保护政策</strong></span>
    </p>
    <p class="p2">
      <span class="s2"><br/></span>
    </p>
    <p class="p2">
      <span class="s2">11.1 </span><span class="s3">隐私保护政策</span>
    </p>
    <p class="p2">
      <span class="s2"><br/></span>
    </p>
    <p class="p2">
      <span class="s2">11.1.1 </span><span class="s3">关于一游</span><span class="s2"> UNU TRAVEL</span><span
      class="s3">使用你个人资料的相关政策，请阅读本公司现行有效的《隐私保护政策》，此等政策已纳入本《使用条款》。当你接受本《使用条款》，即表示你同意《隐私保护政策》，且同意受此等政策所约束。</span>
    </p>
    <p class="p9">
      <span class="s3"><strong><br/></strong></span>
    </p>
    <p class="p9">
      <span class="s3"><strong>12. </strong></span><span class="s1"><strong>保障</strong></span>
    </p>
    <p class="p4">
      <span class="s3"><br/></span>
    </p>
    <p class="p4">
      <span class="s3">12.1 </span><span class="s1">一游</span><span class="s3"> UNU TRAVEL</span><span
      class="s1">保障条款</span>
    </p>
    <p class="p4">
      <span class="s2"><br/></span>
    </p>
    <p class="p4">
      <span class="s2">12.1.1 </span><span class="s3">对于你因你本人或使用你的一游</span><span class="s2"> UNU TRAVEL</span><span
      class="s3">使用者身份的任何第三方存取本网站或使用本网站、违反本《使用条款》、侵犯知识产权或任何人、团体之权利，而造成任何第三方提出的主张或要求，你应保障一游</span><span
      class="s2"> UNU TRAVEL</span><span class="s3">、一游</span><span class="s2"> UNU TRAVEL</span><span
      class="s3">的控股公司、子公司、关系企业、高阶主管、董事及员工不受任何损害（包括但不限于所有损失、责任、和解、成本及律师费），如有损害，应给予赔偿。</span>
    </p>
    <p class="p4">
      <span class="s2"><br/></span>
    </p>
    <p class="p4">
      <span class="s2">12.2 </span><span class="s3">一游</span><span class="s2"> UNU TRAVEL</span><span
      class="s3">介入</span><span class="s2"> </span><span
      class="s3">如需要，本公司得参与对任何主张或法律行动的辩护，以及任何和解协商过程。未获得本公司事前书面许可，你不得做出任何可能不利于本公司权利或义务的和解。本公司有权自行出资，单独对任何主张或法律行动采取辩护和控制。</span>
    </p>
    <p class="p2">
      <span class="s2"><strong><br/></strong></span>
    </p>
    <p class="p2">
      <span class="s2"><strong>13. </strong></span><span class="s1"><strong>免责声明与责任限制</strong></span>
    </p>
    <p class="p4">
      <span class="s3"><br/></span>
    </p>
    <p class="p4">
      <span class="s3">13.1 </span><span class="s1">关系</span>
    </p>
    <p class="p4">
      <span class="s2"><br/></span>
    </p>
    <p class="p4">
      <span class="s2">13.1.1 </span><span class="s3">关于存取和使用本网站及内容，一游</span><span class="s2"> UNU TRAVEL</span><span
      class="s3">与你无特殊关系，且对你无受托人责任。你在此承认一游</span><span class="s2"> UNU TRAVEL</span><span
      class="s3">未控制且无责任对以下事项采取任何行动：（</span><span class="s2">a</span><span class="s3">）哪些使用者可存取本网站；（</span><span
      class="s2">b</span><span class="s3">）你透过本网站存取哪些内容；（</span><span class="s2">c</span><span
      class="s3">）内容对你有何影响；（</span><span class="s2">d</span><span class="s3">）你如何解释或使用内容；（</span><span
      class="s2">e</span><span class="s3">）你因为暴露于内容而可能采取的行动。</span>
    </p>
    <p class="p4">
      <span class="s3"><br/></span>
    </p>
    <p class="p4">
      <span class="s3">13.2 </span><span class="s1">服务与说明</span>
    </p>
    <p class="p4">
      <span class="s2"><br/></span>
    </p>
    <p class="p4">
      <span class="s2">13.2.1 </span><span
      class="s3">你承认且同意本网站服务可能由其他业者或第三方提供，且其包括建议或推荐内容可能是由其他业者或本网站的使用者提供。关于服务说明是否正确，或透过本网站提供或购买的服务之建议或推荐是否适当，一游</span><span
      class="s2"> UNU TRAVEL</span><span class="s3">不提供任何声明或保证。</span>
    </p>
    <p class="p4">
      <span class="s3"><br/></span>
    </p>
    <p class="p4">
      <span class="s3">13.3 </span><span class="s1">责任免除</span>
    </p>
    <p class="p4">
      <span class="s2"><br/></span>
    </p>
    <p class="p4">
      <span class="s2">13.3.1 </span><span class="s3">本网站、一游</span><span class="s2"> UNU TRAVEL</span><span
      class="s3">的控股公司、子公司、关系企业、高阶主管、董事及</span><span class="s2">/</span><span
      class="s3">或员工在任何情况下，皆不需对以下情况造成的任何获利损失或任何间接、必然、特殊、附带或惩罚性的损害负责：（</span><span class="s2">a</span><span
      class="s3">）本网站；（</span><span class="s2">b</span><span class="s3">）本《使用条款》；（</span><span class="s2">c</span><span
      class="s3">）你或第三方违反本《使用条款》；（</span><span class="s2">d</span><span
      class="s3">）使用者之间任何实际或意图的通讯或交易，即使我们已被告知有此等损害的可能性。</span>
    </p>
    <p class="p4">
      <span class="s2"><br/></span>
    </p>
    <p class="p4">
      <span class="s2">13.3.2 </span><span class="s3">不论损害是否由以下原因造成，前述之限制及排除规定均适用：（</span><span class="s2">a</span><span
      class="s3">）违反合约；（</span><span class="s2">b</span><span class="s3">）违反保证；（</span><span class="s2">c</span><span
      class="s3">）严格责任；（</span><span class="s2">d</span><span class="s3">）侵权；（</span><span class="s2">e</span><span
      class="s3">）疏忽；或（</span><span class="s2">f</span><span class="s3">）任何其他法律行动的原因，在适用法律允许的最大范围内皆有效。</span>
    </p>
    <p class="p4">
      <span class="s2"><br/></span>
    </p>
    <p class="p4">
      <span class="s2">13.3.3 </span><span
      class="s3">透过本网站取得的所有内容、软件、功能、以及信息，皆以其「原貌」提供。在适用法律允许的最大范围内，本公司不会明示或暗示任何形式之声明或保证，包括但不限于本网站上的内容、软件、透过本网站存取的数据、信息、功能、产品、服务或对第三方的超链接，或对于违反安全行为透过本网站传输敏感信息，或未侵权、可操作性、适用于特定目的或正确性，本公司未保证本网站所包含的功能或任何数据或内容不会被中断或无错误。</span>
    </p>
    <p class="p4">
      <span class="s2"><br/></span>
    </p>
    <p class="p4">
      <span class="s2">13.3.4 </span><span
      class="s3">你承认且同意任何来往本网站的传输内容并非机密性，且你的通讯内容可被他人阅读或截取。你进一步承认且同意，你与我们的通讯内容，以及在本网站上张贴的信息，包括旅游行程及</span><span
      class="s2">/</span><span class="s3">或活动，除了本《使用条款》所规定者外，你与本公司之间未建立任何机密、信托、暗示的合约或其他关系。</span>
    </p>
    <p class="p4">
      <span class="s2"><br/></span>
    </p>
    <p class="p4">
      <span class="s2">13.3.5 </span><span
      class="s3">你承认且同意，你不会要求本公司或设法让本公司对任何其他业者或第三方提供的内容负责，而且你进一步承认且同意，本公司非其他业者或第三方提供服务之相关交易的当事人。对于本网站上任何交易、旅游行程及／或活动的安全性，以及其他业者或第三方提供的任何列表或其他内容之真实性或正确性，本公司无任何控制权，且未提供任何保证。</span>
    </p>
    <p class="p4">
      <span class="s3"><br/></span>
    </p>
    <p class="p4">
      <span class="s3">13.4 </span><span class="s1">补救</span>
    </p>
    <p class="p4">
      <span class="s2"><br/></span>
    </p>
    <p class="p4">
      <span class="s2">13.4.1 </span><span
      class="s3">如果你对本网站不满意、不同意本《使用条款》的任何部分，或者对本公司或本网站的其他使用者有其他与本《使用条款》或本网站有关的争议或主张，唯一补救方法即为停止使用本网站。</span>
    </p>
    <p class="p4">
      <span class="s2"><br/></span>
    </p>
    <p class="p4">
      <span class="s2">13.4.2 </span><span class="s3">在任何情况下，且在适用法律允许的最大范围内，本公司或一游</span><span
      class="s2"> UNU TRAVEL</span><span class="s3">任何会员就本网站所产生的相关事宜，对你或第三方的责任仅限于下述两种金额之较大者：（</span><span
      class="s2">a</span><span class="s3">）</span><span class="s2"> </span><span
      class="s3">在造成责任的法律行动前十二个月您支付给我们的费用金额；或（</span><span class="s2">b</span><span class="s3">）对于所有主张累计最多</span><span
      class="s2">500.00</span><span class="s3">元港币。</span>
    </p>
    <p class="p2">
      <span class="s2"><strong><br/></strong></span>
    </p>
    <p class="p2">
      <span class="s2"><strong>14. </strong></span><span class="s1"><strong>与第三方的互动</strong></span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">14.1 </span><span class="s3">第三方网站连结</span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">14.1.1 </span><span class="s3">本网站可能包含某些非一游</span><span class="s2"> UNU TRAVEL</span><span
      class="s3">拥有或控制的第三方网站或服务（下称「第三方网站」）之连结，即使本网站上有前往第三方网站的连结，并不表示本公司赞同或推荐此等第三方网站，或此等第三方网站上可取得的信息、产品、广告或其他数据。</span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">14.1.2 </span><span
      class="s3">当你存取第三方网站时，你需自行承担风险。你在此声明并保证，你已阅读与您使用服务有关的任何第三方网站的所有可适用政策，且同意受此等政策约束，除了你在本《使用条款》下的义务，你也会遵守第三方网站的所有可适用政策。</span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">14.1.3 </span><span class="s3">对于第三方网站的内容、正确性、隐私政策或实务，以及第三方网站上表达的意见，一游</span><span
      class="s2"> UNU TRAVEL</span><span class="s3">并无任何控制权，且不需负任何责任。此外，一游</span><span
      class="s2"> UNU TRAVEL</span><span class="s3">不会，也无法监督、证实、审查或编辑任何第三方网站的内容。若你使用一游</span><span
      class="s2"> UNU TRAVEL</span><span class="s3">的服务，即表示你同意免除一游</span><span class="s2"> UNU TRAVEL</span><span
      class="s3">对于因你使用任何第三方网站而产生的任何及所有责任。</span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">14.1.4 </span><span class="s3">若你透过一游</span><span class="s2"> UNU TRAVEL</span><span
      class="s3">的服务而与任何组织及</span><span class="s2">/</span><span
      class="s3">或个人有所互动（包括但不限于其他业者），包括产生商品或服务的付款和运送，以及与此等交易有关的任何其他条款、条件、保证或声明，皆为你与此等组织及</span><span class="s2">/</span><span
      class="s3">或个人之间的关系，与本公司无涉。</span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">14.1.5 </span><span class="s3">你在与任何此等第三方从事任何在线或脱机的交易之前，应进行你认为需要或适当的调查。</span>
    </p>
    <p class="p3">
      <span class="s3"><br/></span>
    </p>
    <p class="p3">
      <span class="s3">14.2 </span><span class="s1">一游</span><span class="s3"> UNU TRAVEL</span><span
      class="s1">的责任</span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">14.2.1 </span><span class="s3">你同意一游</span><span class="s2"> UNU TRAVEL</span><span
      class="s3">不需为此等交易产生的任何损失或损害负责。若该网站的参与者之间或使用者与任何第三方之间发生争议，你了解且同意一游</span><span class="s2"> UNU TRAVEL</span><span
      class="s3">无义务被牵涉其中。若你与其中一个或多个其他使用者或第三方发生争议，你在此对一游</span><span class="s2"> UNU TRAVEL</span><span
      class="s3">、其控股公司、子公司、高阶主管、董事、员工、代理人及权利继承人免除因此等争议而产生或相关的任何种类或性质之主张、要求及损害（实际及衍生）、已知或未知、疑似或意料外、已揭露或未揭露的主张、要求及损害。</span>
    </p>
    <p class="p9">
      <span class="s3"><strong><br/></strong></span>
    </p>
    <p class="p9">
      <span class="s3"><strong>15. </strong></span><span class="s1"><strong>付款</strong></span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">15.1 </span><span class="s3">为确保在争议管理、退款、订单取消（如适用）以及客户服务方面提供全面的运营支持，以下一游</span><span
      class="s2"> UNU TRAVEL</span><span class="s3">授权应对下列货币进行的交负责：</span>
    </p>
    <p class="p3">
      <span class="s1"><br/></span>
    </p>
    <p class="p3">
      <span class="s1">以</span><span class="s3">SGD/TWD/MYR/EUR/GBP/CHF/DKK/ISK/NOK/SEK/RUB</span><span
      class="s1">付款的交易；及以其他所有货币付款的交易，又一科技有限公司</span><span class="s3"> UNU TECHNOLOGY LIMITED</span><span
      class="s1">。</span>
    </p>
    <p class="p9">
      <span class="s3"><strong><br/></strong></span>
    </p>
    <p class="p9">
      <span class="s3"><strong>16. </strong></span><span class="s1"><strong>终止</strong></span>
    </p>
    <p class="p4">
      <span class="s3">16.1 </span><span class="s1">由一游</span><span class="s3"> UNU TRAVEL</span><span
      class="s1">终止</span>
    </p>
    <p class="p4">
      <span class="s2"><br/></span>
    </p>
    <p class="p4">
      <span class="s2">16.1.1 </span><span class="s3">只要你使用本网站或服务，本《使用条款》即具有完全效力。一游</span><span
      class="s2"> UNU TRAVEL</span><span
      class="s3">得随时基于任何理由，且无须提出任何警告，即终止或暂停你存取服务的权利或您的会员资格，与你会员资格有关的所有信息将因此丧失或销毁。若你违反本《使用条款》的任何条款或条件，一游</span><span
      class="s2"> UNU TRAVEL</span><span class="s3">也将立即终止或暂停任何及所有对你提供的服务，以及你存取本网站的权利，且一游</span><span
      class="s2"> UNU TRAVEL</span><span class="s3">无须发出任何事前通知，亦无须负任何责任。</span>
    </p>
    <p class="p4">
      <span class="s3"><br/></span>
    </p>
    <p class="p4">
      <span class="s3">16.2 </span><span class="s1">终止效力</span>
    </p>
    <p class="p4">
      <span class="s2"><br/></span>
    </p>
    <p class="p4">
      <span class="s2">16.2.1 </span><span
      class="s3">在你的账户被终止时，你使用服务、存取本网站及任何内容的权利将立即停止，但本《使用条款》中的任何规定如根据其性质，即使你的账户被终止后应持续生效，应于终止后存续，包括但不限于所有权规定、免保证声明及责任限制。</span>
    </p>
    <p class="p4">
      <span class="s2"><br/></span>
    </p>
    <p class="p4">
      <span class="s2">16.2.2 </span><span class="s3">如本《使用条款》因你违反规定而终止，一游</span><span
      class="s2"> UNU TRAVEL</span><span class="s3">有权取消你于终止前已购买且尚未使用的凭证，而无需对你退款或负任何责任。</span>
    </p>
    <p class="p2">
      <span class="s2"><strong><br/></strong></span>
    </p>
    <p class="p2">
      <span class="s2"><strong>17. </strong></span><span class="s1"><strong>护照、签证与保险</strong></span>
    </p>
    <p class="p4">
      <span class="s3"><br/></span>
    </p>
    <p class="p4">
      <span class="s3">17.1 </span><span class="s1">你的责任</span>
    </p>
    <p class="p4">
      <span class="s2"><br/></span>
    </p>
    <p class="p4">
      <span class="s2">17.1.1 </span><span class="s3">不论国籍和目的地为何，所有旅客应自行向其将造访的国家之领事馆询问现行有效的入境规定。</span>
    </p>
    <p class="p4">
      <span class="s2"><br/></span>
    </p>
    <p class="p4">
      <span class="s2">17.1.2 </span><span class="s3">由于签证及健检的规定可能临时变更，且无任何通知，我们建议你于旅游前，向适当的领事馆询问健检与签证的相关规定。</span>
    </p>
    <p class="p4">
      <span class="s2"><br/></span>
    </p>
    <p class="p4">
      <span class="s2">17.1.3 </span><span class="s3">我们强烈建议你于出发旅游前购买综合旅游保险。</span>
    </p>
    <p class="p9">
      <span class="s3"><strong><br/></strong></span>
    </p>
    <p class="p9">
      <span class="s3"><strong>18. </strong></span><span class="s1"><strong>准据法</strong></span>
    </p>
    <p class="p9">
      <span class="s1"><strong><br/></strong></span>
    </p>
    <p class="p3">
      <span class="s2">18.1 </span><span class="s3">本《使用条款》应由中华人民共和国香港特别行政区的法律来规范，你同意接受香港法院的非专属管辖权。</span>
    </p>
    <p class="p9">
      <span class="s3"><strong><br/></strong></span>
    </p>
    <p class="p9">
      <span class="s3"><strong>19. </strong></span><span class="s1"><strong>其他规定</strong></span>
    </p>
    <p class="p9">
      <span class="s3"><br/></span>
    </p>
    <p class="p9">
      <span class="s3">19.1 </span><span class="s1">可分割性</span>
    </p>
    <p class="p9">
      <span class="s2"><br/></span>
    </p>
    <p class="p9">
      <span class="s2">19.1.1 </span><span
      class="s3">如本《使用条款》的任何规定被认为无法施行或无效，该规定应在必要的最低程度内受限制或删除，使本《使用条款》仍具有完全之效力且可施行。</span>
    </p>
    <p class="p4">
      <span class="s3"><br/></span>
    </p>
    <p class="p4">
      <span class="s3">19.2 </span><span class="s1">转让</span>
    </p>
    <p class="p4">
      <span class="s2"><br/></span>
    </p>
    <p class="p4">
      <span class="s2">19.2.1 </span><span class="s3">除非获得一游</span><span class="s2"> UNU TRAVEL</span><span
      class="s3">的事前书面同意，否则你不得转让、移转或转授权本《使用条款》，但一游</span><span class="s2"> UNU TRAVEL</span><span
      class="s3">得移转、转让或委托本《使用条款》及其权利与义务，而无需获得你的同意。</span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">19.3 </span><span
      class="s3">本《使用条款》的条款及细则皆以英文编写而成，任何其他语言的翻译版本仅供参考。如任何翻译版本与英文原文相异，一切皆以英文版本为准。</span>
    </p>
    <p class="p9">
      <span class="s3"><strong><br/></strong></span>
    </p>
    <p class="p9">
      <span class="s3"><strong>20. </strong></span><span class="s1"><strong>联络</strong></span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">20.1 </span><span class="s3">如欲报告任何违反本《使用条款》之情况，或提出有关本《使用条款》或服务的疑问，请透过电子邮件（</span><span
      class="s2">support@unutravel.com</span><span class="s3">）联络我们。</span>
    </p>
    <p class="p5">
      <span class="s2"><br/></span>
    </p>
    <p class="p5">
      <span class="s2">2019</span><span class="s3">年</span><span class="s2">9</span><span class="s3">月</span><span
      class="s2">16</span><span class="s3">日最近一次更新</span>
    </p>
    <p>
      <br/>
    </p>
  </div>
</template>
<script>
  export default {
    name: 'TermsAndConditionsCn'
  };
</script>
