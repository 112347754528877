<template>
  <div class="rich-text">
    <p>
      <strong><br/></strong>
    </p>
    <p style="text-align: center;">
      <span style="font-size: 18px;"><strong>使用條款（企業及團體）</strong></span><br/>
    </p>
    <p>
      <strong><br/></strong>
    </p>
    <p class="p2">
      <span class="s2"><strong>1. </strong></span><span class="s3"><strong>你的協議</strong></span>
    </p>
    <p class="p2">
      <span class="s3"><strong><br/></strong></span>
    </p>
    <p class="p3">
      <span class="s2">1.1 </span><span class="s3">此網站（</span><span class="s2">“www.unutravel.com”</span><span
      class="s3">）由香港註冊公司</span><span class="s2">UNU TECHNOLOGY LIMITED</span><span class="s3">營運。使用此網站及由</span><span
      class="s2">UNU TECHNOLOGY LIMITED</span><span class="s3">及其關聯公司（合稱</span><span class="s2">&quot;</span><span
      class="s3">一遊</span><span class="s4"> </span><span class="s2">UNU TRAVEL&quot;</span><span
      class="s3">）或第三方營運者（</span><span class="s2">“</span><span class="s3">營運者</span><span class="s2">”</span><span
      class="s3">）透過此網站所提供的服務（</span><span class="s2">“</span><span class="s3">服務</span><span class="s2">”</span><span
      class="s3">）前請先細閱條款（</span><span class="s2">“</span><span class="s3">本《使用條款》</span><span class="s2">”</span><span
      class="s3">）。此條款中使用的</span><span class="s2">“</span><span class="s3">你</span><span class="s2">”</span><span
      class="s3">及</span><span class="s2">“</span><span class="s3">你的</span><span class="s2">”</span><span
      class="s3">包括（</span><span class="s2">1</span><span class="s3">）任何進入此網站的人及（</span><span class="s2">2</span><span
      class="s3">）任何透過你購買服務的人。</span>
    </p>
    <p class="p2">
      <span class="s2"><strong><br/></strong></span>
    </p>
    <p class="p2">
      <span class="s2"><strong>2.</strong></span><span class="s3"><strong>《使用條款》變更</strong></span>
    </p>
    <p class="p4">
      <span class="s3"><br/></span>
    </p>
    <p class="p4">
      <span class="s3">2.1 </span><span class="s5">一遊</span><span class="s4"> </span><span
      class="s3">UNU TRAVEL</span><span class="s5">修訂</span>
    </p>
    <p class="p4">
      <span class="s2"><br/></span>
    </p>
    <p class="p4">
      <span class="s2">2.1.1 </span><span class="s3">一遊</span><span class="s4"> </span><span
      class="s2">UNU TRAVEL</span><span
      class="s3">按本公司的絕對酌情權可隨時及不定時修改或更新本《使用條款》，且無須提前通知。你應定期查閱最新版本的《使用條款》所述之約束。如果一遊</span><span class="s4"> </span><span
      class="s2">UNU TRAVEL</span><span class="s3">修改或更新本《使用條款》，一遊</span><span class="s4"> </span><span
      class="s2">UNU TRAVEL</span><span class="s3">會將變更或修正的內容張貼在此網頁上，並在此網頁的最下方指出本《使用條款》最近一次修訂的日期。</span>
    </p>
    <p class="p4">
      <span class="s2"><br/></span>
    </p>
    <p class="p4">
      <span class="s2">2.1.2 </span><span
      class="s3">若你於該等更改後繼續使用本網站，將意味閣下同意並接納經修訂的《使用條款》。假若你不同意本《使用條款》的任何修訂，請立即停止使用或存取本網站及服務。你有責任定期查閱本網站，以審查及確認本《使用條款》之任何變更。</span>
    </p>
    <p class="p4">
      <span class="s2"><br/></span>
    </p>
    <p class="p4">
      <span class="s2">2.1.3 </span><span class="s3">此外，在使用服務時，你應接受任何不定時更新的所有與服務相關的額外條款約束，以及一遊</span><span
      class="s4"> </span><span class="s2">UNU TRAVEL</span><span
      class="s3">不時採納的隱私政策（下稱《隱私保護政策》）。本公司在此明示，所有此等條款被提及時，均被納入本《使用條款》。</span>
    </p>
    <p class="p2">
      <span class="s2"><strong><br/></strong></span>
    </p>
    <p class="p2">
      <span class="s2"><strong>3 </strong></span><span class="s3"><strong>登入及使用服務</strong></span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">3.1 </span><span class="s3">內容擁有權</span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">3.1.1 </span><span class="s3">此網站、網域名稱（</span><span class="s4"> </span><span
      class="s2">www.unutravel.com</span><span class="s4"> </span><span
      class="s3">）、子網域、技術功能、資料內容及應用服務（包括但不限於行動應用服務）或與此相關由一遊</span><span class="s4"> </span><span
      class="s2">UNU TRAVEL</span><span class="s3">定期提供的服務，一律由一遊</span><span class="s4"> </span><span
      class="s2">UNU TRAVEL</span><span class="s3">所擁有和經營。</span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">3.2 </span><span class="s3">存取服務之權責</span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">3.2.1 </span><span class="s3">在本《使用條款》的規範下，一遊</span><span class="s4"> </span><span
      class="s2">UNU TRAVEL</span><span
      class="s3">可自行或代表其他營運業者提供服務，此等服務在本網站上有更完整的說明。你在本網站上選取的服務只限你個人使用，不得供給任何第三方使用或獲取任何利益。「服務」一詞包括但不限於使用本網站，與及使用一遊</span><span
      class="s4"> </span><span class="s2">UNU TRAVEL</span><span class="s3">自行或代表其他業者在本網站上提供的任何服務。一遊</span><span
      class="s4"> </span><span class="s2">UNU TRAVEL</span><span
      class="s3">有權不定期更改、暫停或終止任何服務，包括任何技術功能、資料存庫或內容訊息。一遊</span><span class="s4"> </span><span
      class="s2">UNU TRAVEL</span><span class="s3">亦有權對個別服務加上規範、修訂條款，或限制你存取某部分或全部的服務，而無需提前通知。</span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">3.2.2 </span><span class="s3">一遊</span><span class="s4"> </span><span
      class="s2">UNU TRAVEL</span><span class="s3">不保證其服務能時刻運作或不受外在因素影響。一遊</span><span class="s4"> </span><span
      class="s2">UNU TRAVEL</span><span
      class="s3">將不會對你於任何時間就無法運作之服務負責。你有責任作出所有必要的安排以存取服務，及確保透過網路連線而存取服務的所有人都了解本《使用條款》和其他與服務有關可適用條款與細則。</span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">3.2.3</span><span class="s3">當你連結到本網站，一遊</span><span class="s4"> </span><span
      class="s2">UNU TRAVEL</span><span class="s3">有絕對酌情權決定撤消你在任何時候連結到本網站的權利。一遊</span><span class="s4"> </span><span
      class="s2">UNU TRAVEL</span><span class="s3">亦有權要求你必須先獲得書面許可後，才能連結到本網站。</span>
    </p>
    <p class="p2">
      <span class="s2"><strong><br/></strong></span>
    </p>
    <p class="p2">
      <span class="s2"><strong>4. </strong></span><span class="s3"><strong>網站與內容</strong></span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">4.1 </span><span class="s3">內容的使用</span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">4.1.1 </span><span
      class="s3">本網站採用或呈現的所有資料，包括但不限於文字、數據、圖像、文章、照片、影像、插圖、影片、聲效及其他素材及資料（下統稱為「內容」），皆受著作權及</span><span class="s4"> </span><span
      class="s2">/ </span><span class="s3">或知識產權的保護。本網站所有內容一律只限你個人且</span><span class="s7"> </span><span
      class="s3">非商業性質使用的服務，並必須根據本《使用條款》之規範使用。</span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">4.1.2 </span><span class="s3">當一遊</span><span class="s4"> </span><span
      class="s2">UNU TRAVEL</span><span class="s3">同意授權你存取本網站及</span><span class="s2">/</span><span
      class="s3">或網站內容，此等授權應為非專屬、不得移轉，並只限根據本《使用條款》之規範而執行相關存取。一遊</span><span class="s4"> </span><span
      class="s2">UNU TRAVEL</span><span class="s3">有絕對酌情權可隨時修訂、移除或變更本網站部分或所有內容的呈現、本質或功能，而無須提前通知。</span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">4.1.3 </span><span
      class="s3">你必須遵守本網站及透過本網站存取的內容所包含的所有著作權公告、商標規範、相關資訊權限，且在未獲得個別持有人的事先書面同意前，你不得以違反第三方權利的方式，以任何理由或目的使用、複製、重製、修改、翻譯、刊載、傳播、傳輸、發放、執行、上載、呈現、授權、出售或以其他方式利用本網站、網站內容、第三方提交之資料，或其他非你所擁有的專屬權利。</span>
    </p>
    <p class="p4">
      <span class="s3"><br/></span>
    </p>
    <p class="p4">
      <span class="s3">4.2 </span><span class="s5">一遊</span><span class="s4"> </span><span
      class="s3">UNU TRAVEL</span><span class="s5">對網站及內容之權責</span>
    </p>
    <p class="p4">
      <span class="s2"><br/></span>
    </p>
    <p class="p4">
      <span class="s2">4.2.1 </span><span class="s3">一遊</span><span class="s4"> </span><span
      class="s2">UNU TRAVEL</span><span class="s3">無法保證你在使用本網站的過程中，可能與你互動的任何其他使用者為何人。一遊</span><span
      class="s4"> </span><span class="s2">UNU TRAVEL</span><span
      class="s3">亦無法保證其他使用者或其他業者可能提供的任何內容、資料或資訊是否真實且正確。你須對使用本網站存取任何內容自行承擔風險，且若任何人因你的使用而產生任何損害或損失，皆必須由你個人單獨承擔責任。</span>
    </p>
    <p class="p4">
      <span class="s2"><br/></span>
    </p>
    <p class="p4">
      <span class="s2">4.2.2 </span><span class="s3">一遊</span><span class="s4"> </span><span
      class="s2">UNU TRAVEL</span><span
      class="s3">在任何情況下皆無須對任何內容負責，包括但不限於內容的錯誤或疏漏，或因使用、公佈、使用電子郵件傳送、存取、傳輸網站及網站的任何內容而產生的任何損害或損失。</span>
    </p>
    <p class="p2">
      <span class="s2"><strong><br/></strong></span>
    </p>
    <p class="p2">
      <span class="s2"><strong>5. </strong></span><span class="s3"><strong>知識財產權利</strong></span>
    </p>
    <p class="p4">
      <span class="s3"><br/></span>
    </p>
    <p class="p4">
      <span class="s3">5.1 </span><span class="s5">知識財產</span>
    </p>
    <p class="p4">
      <span class="s2"><br/></span>
    </p>
    <p class="p4">
      <span class="s2">5.1.1 </span><span class="s3">與本網站有關的所有知識產權皆屬於一遊</span><span class="s4"> </span><span
      class="s2">UNU TRAVEL</span><span class="s3">，或已授權予一遊</span><span class="s4"> </span><span
      class="s2">UNU TRAVEL</span><span class="s3">用於本網站。根據可適用的著作權法、國際公約及其他相關的知識產權法律，本網站、服務及內容為集體作品及</span><span
      class="s4"> </span><span class="s2">/ </span><span class="s3">或編輯內容，應受著作權及其他知識產權法所保護。你承諾：（</span><span
      class="s2">a</span><span
      class="s3">）你不得對本網站之任何內容、軟體、資料或服務進行全部或部分修正、刊載、傳輸、參與轉移或出售、重製、以之為基礎創造衍生作品、發放、執行、呈現或以任何方式利用。（</span><span
      class="s2">b</span><span class="s3">）你僅限為個人且非商業性質之用途而下載或複製內容</span><span class="s4"> </span><span
      class="s2">(</span><span class="s3">以及呈現於本網站或與服務相關的其他項目</span><span class="s2">)</span><span
      class="s3">，並確保你已遵守此等內容所含的所有著作權及其他條款公告。（</span><span class="s2">c</span><span
      class="s3">）你不得以任何形式儲存相當大部分的任何內容。除個人非商業性質之用途外，在未獲得一遊</span><span class="s4"> </span><span
      class="s2">UNU TRAVEL</span><span class="s3">或著作權公告中所指明的著作權持有人之事前書面允許前，本公司明確地禁止你複製或儲存任何內容。</span>
    </p>
    <p class="p4">
      <span class="s2"><br/></span>
    </p>
    <p class="p4">
      <span class="s2">5.1.2</span><span class="s3">一遊</span><span class="s4"> </span><span class="s2">UNU TRAVEL</span><span
      class="s3">及你及其職員承諾在履行本協定期間及在本協定期滿後不對對方所擁有的商標、企業名稱、功能變數名稱等進行貶低或者其它任何損害，也不對對方互聯網網頁或者網站進行任何貶低、抄襲、歪曲、破壞或其它損害。</span>
    </p>
    <p class="p4">
      <span class="s2"><br/></span>
    </p>
    <p class="p4">
      <span class="s2">5.1.3 </span><span class="s3">本協議有效期內及本協議終止或者解除後，一遊</span><span class="s4"> </span><span
      class="s2">UNU TRAVEL</span><span
      class="s3">及你及其職員承諾不向任何非本協定簽署方（包括但不限於與對方構成商業競爭關係的企業、商業機構或者組織）提供有關雙方業務、技術等一切相關資訊或者資料，否則由一方承擔相應的責任，並賠償另一方因此造成的一切損失。</span>
    </p>
    <p class="p2">
      <span class="s2"><strong><br/></strong></span>
    </p>
    <p class="p2">
      <span class="s2"><strong>6. </strong></span><span class="s3"><strong>使用者提交之資料</strong></span>
    </p>
    <p class="p4">
      <span class="s3"><br/></span>
    </p>
    <p class="p4">
      <span class="s3">6.1 </span><span class="s5">上載資料</span>
    </p>
    <p class="p4">
      <span class="s2"><br/></span>
    </p>
    <p class="p4">
      <span class="s2">6.1.1 </span><span class="s3">在存取本網站或使用服務時，你提供的資訊可能會被一遊</span><span class="s4"> </span><span
      class="s2">UNU TRAVEL</span><span class="s3">及</span><span class="s2">/</span><span
      class="s3">或與服務相關其他業者採用，而本網站的其他使用者將可能看到這些資訊。當你將資訊或內容張貼於本網站，或以其他方式將內容、資料或資訊（下統稱「使用者提交之資料」）提供給一遊</span><span
      class="s4"> </span><span class="s2">UNU TRAVEL</span><span class="s3">及</span><span class="s2">/</span><span
      class="s3">或與服務相關的其他業者時，即表示：（</span><span class="s2">a</span><span class="s3">）你授權一遊</span><span
      class="s4"> </span><span class="s2">UNU TRAVEL</span><span class="s3">及其他業者在提供服務及經營本網站和一遊</span><span
      class="s4"> </span><span class="s2">UNU TRAVEL</span><span
      class="s3">的業務時，使用部份或全部此等使用者提交之資料（包括此等資料的所有相關知識產權），包括但不限於使用任何媒體形式及透過任何媒體管道推廣和重新分配部分或所有服務（及衍生的作品），前述之授權為非專屬、可用於全球、免權利費、永久、不得撤銷、可轉授權且可移轉之權利；（</span><span
      class="s2">b</span><span class="s3">）你同意並授權一遊</span><span class="s4"> </span><span
      class="s2">UNU TRAVEL</span><span class="s3">根據不定時生效的《隱私保護政策》來使用你的個人資料；（</span><span class="s2">c</span><span
      class="s3">）你也在此授權本網站的每個使用者透過本網站的功能，在本《使用條款》允許的範圍內，在本網站上存取你的使用者提交之資料，並使用、修正、重製、發放、編製衍生作品、呈現和執行此等使用者提交之資料，前述之授權為非專屬之權利；（</span><span
      class="s2">d</span><span class="s3">）你在此確認且同意，一遊</span><span class="s4"> </span><span class="s2">UNU TRAVEL</span><span
      class="s3">有權重新編排、修正、創造衍生作品、摘錄和翻譯你的任何使用者提交之資料。為釐清疑義，除非另有書面議定，前述授予一遊</span><span class="s4"> </span><span
      class="s2">UNU TRAVEL</span><span class="s3">之權利不會影響你對使用者提交之資料的所有權，或授權他人使用此等資料的權利；以及（</span><span
      class="s2">e</span><span class="s3">）你僅此聲明及保證你提交的用戶內容（包括、但不限於：文字、圖像及相片）沒有違反或損害任何現行法律、規章或第三方權益；以及（</span><span
      class="s2">f</span><span class="s3">）你必須獨自負責在本網站上公開張貼或透過本網站私下傳輸的所有使用者提交之資料，一遊</span><span class="s4"> </span><span
      class="s2">UNU TRAVEL</span><span class="s3">不對任何內容的錯誤或疏漏負上任何責任。</span>
    </p>
    <p class="p2">
      <span class="s2"><strong><br/></strong></span>
    </p>
    <p class="p2">
      <span class="s2"><strong>7. </strong></span><span class="s3"><strong>使用者的聲明、保證及承諾</strong></span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">7.1 </span><span class="s3">使用本網站及服務</span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">7.1.1 </span><span class="s3">你向一遊</span><span class="s4"> </span><span
      class="s2">UNU TRAVEL</span><span class="s3">聲明、保證及承諾不會以下述方式向第三方提供任何使用者的資料，或以此等方式使用本網站或服務：（</span><span
      class="s2">a</span><span class="s3">）侵害或違反任何第三方的智慧財產權或專屬權、公開權或隱私權或其他權利；（</span><span class="s2">b</span><span
      class="s3">）違反任何規章法令；（</span><span class="s2">c</span><span
      class="s3">）有害、欺詐、虛假、威脅、濫用、騷擾、侵害、誹謗、下流、淫穢、猥褻，或其他令人難以接受的方式；（</span><span class="s2">d</span><span
      class="s3">）未獲得一遊</span><span class="s4"> </span><span class="s2">UNU TRAVEL</span><span
      class="s3">的事前以書面同意而參與商業性活動及</span><span class="s2">/</span><span
      class="s3">或銷售，例如競賽、博弈、以物易物、廣告或層壓式推銷；（</span><span class="s2">e</span><span
      class="s3">）誹謗或假冒任何人或法律實體，包括但不限於一遊</span><span class="s4"> </span><span class="s2">UNU TRAVEL</span><span
      class="s3">的任何員工或代表人；或（</span><span class="s2">f</span><span
      class="s3">）包含病毒程式、特洛伊木馬程式、蠕蟲程式、定時炸彈程式，或其他有害的電腦程式碼、檔案或程式。</span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">7.2 </span><span class="s3">移除使用者提交之資料</span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">7.2.1 </span><span class="s3">一遊</span><span class="s4"> </span><span
      class="s2">UNU TRAVEL</span><span class="s3">有權基於任何理由（包括但不限於收到第三方或主管機關有關此等使用者提交之資料的主張或指控，或一遊</span><span
      class="s4"> </span><span class="s2">UNU TRAVEL</span><span
      class="s3">懷疑你可能已違反任何先前的聲明、保證或承諾）或不具任何理由，隨時將任何使用者提交之資料從本網站移除。</span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">7.3 </span><span class="s3">使用者提交資料之責任</span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">7.3.1 </span><span
      class="s3">你必須獨自對由你所上載、張貼、使用電子郵件傳送、傳輸，或以其他方式發放，或連接與本網站有關的所有使用者提交之資料負上全部責任。</span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">7.3.2 </span><span class="s3">你在此聲明且同意，你須獨自為所提交的個人使用者資料張貼或刊載於本網站負責。你且向一遊</span><span
      class="s4"> </span><span class="s2">UNU TRAVEL</span><span class="s3">聲明、保證及承諾：（</span><span
      class="s2">a</span><span class="s3">）你擁有或具有確實的權利、授權、同意、豁免及</span><span class="s2">/</span><span
      class="s3">或允許，可使用並授權一遊</span><span class="s4"> </span><span class="s2">UNU TRAVEL</span><span
      class="s3">使用任何使用者提交之資料的所有著作權、商標或其他專屬或智識產權，因此本網站及本《使用條款》可納入和使用此等資料；以及（</span><span class="s2">b</span><span
      class="s3">）使用者提交之資料以及你張貼、上載、刊載、提交或傳輸此等資料，或一遊</span><span class="s4"> </span><span
      class="s2">UNU TRAVEL</span><span
      class="s3">在本網站上或透過本網站提供的服務來使用此等資料（或其任何部分），均未侵犯、盜用或違反任何第三方的專利、著作權、商標、商業機密、道德權利、其他專屬知識產權，或者其他公開權或隱私權，且未違反任何可適用的法令規章。</span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">7.3.3 </span><span class="s3">你必須對使用本網站及</span><span class="s2">/</span><span
      class="s3">或服務的所有相關活動負上全部責任。你且進一步向客戶聲明、保證及承諾，你不應：（</span><span class="s2">a</span><span
      class="s3">）從事任何詐欺、濫用或其他不合法的活動，若你從事此等活動，我們可能終止你存取或使用本網站及</span><span class="s2">/</span><span
      class="s3">或服務的權利；或（</span><span class="s2">b</span><span
      class="s3">）出售或轉售從本網站或移動應用程序或通過本網站或移動應用程序獲得的任何產品，服務或預訂</span><span class="s2">; </span><span
      class="s3">（</span><span class="s2">c</span><span
      class="s3">）將本網站或移動應用程序用於商業或競爭活動或目的，或用於進行投機，虛假或欺詐性預訂或預期需求的任何預訂</span><span class="s2">; </span><span
      class="s3">或（</span><span class="s2">d</span><span
      class="s3">）張貼或傳輸，或利用他人張貼或傳輸特別設計或故意的任何通訊或誘導內容；為取得本網站其他使用者的密碼、帳戶或私人資料，或（</span><span class="s2">e</span><span
      class="s3">）違反任何電腦網路的安全規定、破解密碼或安全加密碼、移轉或儲存不合法的資料（包括可能被視為具有威脅性或猥褻的資料），或從事任何被明白禁止的違法活動；（</span><span
      class="s2">f</span><span class="s3">）在本網站上執行郵件群組（</span><span class="s2">mail list</span><span
      class="s3">）、列表伺服（</span><span class="s2">listserv</span><span
      class="s3">）或任何其他形式的自動回覆程式或垃圾郵件，或在你未登入本網站的狀態下執行或啟動任何流程，或以其他方式干擾本網站基礎設備的適當運作，或對本網站基建加諸不合理的負擔；或（</span><span
      class="s2">g</span><span class="s3">）使用手動或自動的軟體、裝置或其他流程，對本網站的任何網頁進行「爬梳」（</span><span class="s2">crawl</span><span
      class="s3">）、「抓取」（</span><span class="s2">scrape</span><span class="s3">）或「使用搜尋引擎蜘蛛」（</span><span
      class="s2">spider</span><span class="s3">）；以及（</span><span class="s2">h</span><span
      class="s3">）對本網站的原始碼進行反譯、反向工程，或以其他方式試圖取得本網站的原始碼。</span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">7.3.4 </span><span class="s3">你將自行負責保存、記錄，及申報任何你透過本網站及</span><span class="s2">/</span><span
      class="s3">或服務所提供的活動之相關稅項、責任及政府所需徵收之費用。</span>
    </p>
    <p class="p2">
      <span class="s2"><strong><br/></strong></span>
    </p>
    <p class="p2">
      <span class="s2"><strong>8. </strong></span><span class="s3"><strong>註冊帳戶與安全保障</strong></span>
    </p>
    <p class="p4">
      <span class="s3"><br/></span>
    </p>
    <p class="p4">
      <span class="s3">8.1 </span><span class="s5">開設一遊</span><span class="s4"> </span><span
      class="s3">UNU TRAVEL</span><span class="s5">帳戶</span>
    </p>
    <p class="p4">
      <span class="s2"><br/></span>
    </p>
    <p class="p4">
      <span class="s2">8.1.1 </span><span class="s3">在使用服務的過程中，你可能需應要求在本一遊</span><span class="s4"> </span><span
      class="s2">UNU TRAVEL</span><span class="s3">網站上開設並維持一個一遊</span><span class="s4"> </span><span
      class="s2">UNU TRAVEL</span><span class="s3">帳戶（下稱「一遊</span><span class="s4"> </span><span
      class="s2">UNU TRAVEL</span><span class="s3">帳戶」）。</span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">8.2 </span><span class="s3">提供個人資料</span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">8.2.1 </span><span class="s3">基於部份服務的使用條款，你可能需應要求在一遊</span><span class="s4"> </span><span
      class="s2">UNU TRAVEL</span><span class="s3">上登記及設定密碼和用戶名稱（一遊</span><span class="s4"> </span><span
      class="s2">UNU TRAVEL</span><span class="s3">用戶</span><span class="s2">ID</span><span
      class="s3">）。如果你透過第三方網站或服務登入，一遊</span><span class="s4"> </span><span class="s2">UNU TRAVEL</span><span
      class="s3">將可能要求你的一遊</span><span class="s4"> </span><span class="s2">UNU TRAVEL</span><span
      class="s3">用戶</span><span class="s2">ID</span><span class="s3">與你於第三方網站或服務的用戶名稱一致。</span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">8.2.2 </span><span class="s3">你必須向一遊</span><span class="s4"> </span><span
      class="s2">UNU TRAVEL</span><span class="s3">提供準確、完全及最新的用戶資料。若未有如實提供將被視作違反此使用條款，而有可能被即時終止使用一遊</span><span
      class="s4"> </span><span class="s2">UNU TRAVEL</span><span class="s3">帳戶的權利。</span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">8.2.3 </span><span class="s3">你聲明，你絕不應該：（</span><span class="s2">a</span><span
      class="s3">）選擇或使用其他人的一遊</span><span class="s4"> </span><span class="s2">UNU TRAVEL</span><span
      class="s3">用戶</span><span class="s2">ID</span><span class="s3">以圖模仿該人；或（</span><span class="s2">b</span><span
      class="s3">）未經授權下使用非他人之一遊</span><span class="s4"> </span><span class="s2">UNU TRAVEL</span><span
      class="s3">用戶</span><span class="s2">ID</span><span class="s3">。</span>
    </p>
    <p class="p3">
      <span class="s3"><br/></span>
    </p>
    <p class="p3">
      <span class="s3">8.2.4 </span><span class="s5">一遊</span><span class="s4"> </span><span
      class="s3">UNU TRAVEL</span><span class="s5">保留拒絕註冊或取消一遊</span><span class="s4"> </span><span
      class="s3">UNU TRAVEL</span><span class="s5">帳戶的絕對酌情權。</span>
    </p>
    <p class="p2">
      <span class="s2"><strong><br/></strong></span>
    </p>
    <p class="p2">
      <span class="s2"><strong>9. </strong></span><span class="s3"><strong>評價</strong></span><span
      class="s4"><strong> </strong></span><span class="s2"><strong>– </strong></span><span
      class="s3"><strong>進一步對應</strong></span><span class="s4"><strong> </strong></span><span
      class="s2"><strong>– </strong></span><span class="s3"><strong>使用用戶內容之權利</strong></span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">9.1 </span><span class="s3">為完成訂單，你同意接收預訂確認通知（電子郵件與</span><span class="s2">/</span><span
      class="s3">或</span><span class="s2">app</span><span class="s3">應用程式通知），以及在完成活動訂單後將會收到邀請填寫活動評論的電子郵件或</span><span
      class="s2">app</span><span
      class="s3">應用程式通知。你可自由決定是否填寫。確認訂單通知及活動評論邀請皆是預訂流程的一部份，它們並不屬於廣告通訊或市場推廣的一部份。已完成的用戶活動評論會在</span><span
      class="s2">72</span><span class="s3">小時內上傳至一遊</span><span class="s4"> </span><span
      class="s2">UNU TRAVEL</span><span class="s3">平台上的相關活動頁面，以告知（未來）用戶你對於此活動的服務（水準）和品質的意見。</span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">9.2 </span><span class="s3">透過公佈評論，表明你授予一遊</span><span class="s4"> </span><span
      class="s2">UNU TRAVEL</span><span class="s3">對所有用戶內容完整、永久、免費、可轉讓及不可撤銷的權利。一遊</span><span class="s4"> </span><span
      class="s2">UNU TRAVEL</span><span class="s3">有絕對酌情權自行決定對相關內容進行編輯、調整、拒絕採用或刪除的權利。</span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">9.3 </span><span class="s3">你確認將遵守用戶評論規範，並聲明、保證：</span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">9.3.1 </span><span class="s3">你擁有及有權控制所公佈或發放的用戶內容，或你具有透過平台公佈或發放用戶內容的合法權利；</span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">9.3.2 </span><span class="s3">該內容準確並無誤導性；及</span>對該內容的使用、公佈或其他傳播行為沒有違反使用條款或任何相應法律及規例，且不會違反任何個人或機構的權利，及不會對其造成任何傷害。
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">9.4 </span><span
      class="s3">評論不可含有抵毀、污言穢語、不莊重、威脅及辱罵、鼓吹違法行為，亦不可含有其他人的個人資料如名字，電話號碼或電子郵箱，及不相關內容如推廣訊息、邀請及獎賞資料。此外，評論不可含有誹謗、侵犯性，或違反其他的法律權利的內容。</span>
    </p>
    <p class="p3">
      <span class="s3"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">9.5 </span><span class="s3">你且授予一遊</span><span class="s4"> </span><span
      class="s2">UNU TRAVEL</span><span class="s3">向侵犯你或一遊</span><span class="s4"> </span><span
      class="s2">UNU TRAVEL</span><span class="s3">的權利及違反使用條款的人或機構保持法律追訴權利。你並同意獨自對所提供的用戶內容負上全責。</span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">9.6 </span><span class="s3">由用戶所提交的內容將被視為非機密資訊，一遊</span><span class="s4"> </span><span
      class="s2">UNU TRAVEL</span><span class="s3">亦沒有責任視之為專屬資料。除了以上所述，一遊</span><span class="s4"> </span><span
      class="s2">UNU TRAVEL</span><span class="s3">則保留權利可適當使用該內容，包括及不限於，删除、編輯、更改、不接受，或拒絕公佈。對你提交的內容，一遊</span><span
      class="s4"> </span><span class="s2">UNU TRAVEL</span><span
      class="s3">無需向你支付任何費用，亦無需為你提供編輯、刪除或更改已公佈的內容的機會。一遊</span><span class="s4"> </span><span
      class="s2">UNU TRAVEL</span><span class="s3">沒有責任授予你該內容的署名權，也沒有責任執行第三方的署名權。請參考平台上的使用條款以了解更多。</span>
    </p>
    <p class="p2">
      <span class="s2"><strong><br/></strong></span>
    </p>
    <p class="p2">
      <span class="s2"><strong>10. </strong></span><span class="s3"><strong>訂單確認、票券、憑證、費用及付款</strong></span>
    </p>
    <p class="p4">
      <span class="s3"><br/></span>
    </p>
    <p class="p4">
      <span class="s3">10.1</span><span class="s5">訂單確認</span>
    </p>
    <p class="p4">
      <span class="s2"><br/></span>
    </p>
    <p class="p4">
      <span class="s2">10.1.1 </span><span
      class="s3">部分指定活動或服務接受即時確認。除此類活動或服務外，任何在本網站所述的確認時間僅供參考。實際所需的確認時間，會因應情況而有所調整。</span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">10.2 </span><span class="s3">購買及使用憑證</span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">10.2.1 </span><span
      class="s3">透過本網站，你可訂購由目的地供應商所提供的服務的電子憑證（下稱「憑證」）。在相關供應商的條款限制下，你將收到一封確認你訂單的電子郵件，其中包含憑證的確認號碼（下稱「確認號碼」），以及可列印的版本。</span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">10.2.2 </span><span class="s3">使用你的憑證時，你須準時到達相關供應商指定的集合地點，且提供該供應商要求的文件及</span><span
      class="s2">/</span><span class="s3">或資料，例如你的預訂確認號碼及</span><span class="s2">/</span><span
      class="s3">或列印的憑證。若你無法準時出現，或未能提供必要的文件或資料，你將不會享受該服務或退款。</span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">10.2.3 </span><span class="s3">供應商可能要求你提供載有你照片的身份證明文件，才准許你使用憑證。若你遺失憑證、或憑證被破壞、遭偷竊，一遊</span><span
      class="s4"> </span><span class="s2">UNU TRAVEL</span><span
      class="s3">或供應商均無需負上責任。此外，若法律禁止提供相關服務，憑證將失效。若你試圖以不合法的形式兌換憑證（比方說你欲參加品酒活動，但未達能使用該服務的法定年齡），相關供應商將可拒絕接受你的憑證，且無需安排退款。</span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">10.3 </span><span class="s3">憑證之《使用條款》</span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">10.3.1 </span><span
      class="s3">每項憑證的《使用條款》可能因不同供應商而異，當你於網站進行訂購時，將被告知適用於憑證的任何限制（包括但不限於最低年齡要求）。</span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">10.3.2 </span><span
      class="s3">憑證是一次項目（下稱「項目」）的入場券；憑證上會載明可使用的期限。除非憑證上另有明白規定，否則若你未能在憑證上載明的期限內使用憑證，將不獲退款。</span>
    </p>
    <p class="p3">
      <span class="s3"><br/></span>
    </p>
    <p class="p3">
      <span class="s3">10.4 </span><span class="s5">取消憑證</span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">10.4.1 </span><span class="s3">你可在訂購時在網站上載明的取消期限內，聯絡一遊</span><span class="s4"> </span><span
      class="s2">UNU TRAVEL</span><span
      class="s3">的顧客服務，取消你的憑證。取消的聯絡窗口因個案而異。在成功取消使用憑證且收到必要的通知後，金額將會全數退款至你用來訂購該憑證的信用卡。</span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">10.4.2 </span><span class="s3">提供服務該項目的是供應商，而非一遊</span><span class="s4"> </span><span
      class="s2">UNU TRAVEL</span><span class="s3">，因此該項目的供應商應獨自接受或拒絕你訂購的任何憑證，並負責提供所有相關的服務。</span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">10.4.3 </span><span
      class="s3">若你對該項憑證的服務有任何疑問或投訴，請向相關供應商查詢。所有憑證已支付的費用均不可退款，除非本《使用條款》明確規定。憑證是以訂購當日在網站上載明的貨幣來報價。</span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">10.4.4 </span><span class="s3">若你所訂購的項目被相關供應商取消，一遊</span><span class="s4"> </span><span
      class="s2">UNU TRAVEL</span><span class="s3">會盡快以合理的方法通知你，並會將金額全數退款到你用來訂購該憑證的信用卡。</span>
    </p>
    <p class="p4">
      <span class="s3"><br/></span>
    </p>
    <p class="p4">
      <span class="s3">10.5 </span><span class="s5">要求協助</span>
    </p>
    <p class="p4">
      <span class="s2"><br/></span>
    </p>
    <p class="p4">
      <span class="s2">10.5.1 </span><span
      class="s3">若你試圖根據本《使用條款》及適用於該憑證的相關使用條款來兌換使用憑證，但你無法成功使用（因為供應商的錯誤或其他原因），請透過電子郵箱</span><span
      class="s2">support@unutravel.com</span><span class="s3">聯絡一遊</span><span class="s4"> </span><span
      class="s2">UNU TRAVEL</span><span class="s3">，一遊</span><span class="s4"> </span><span class="s2">UNU TRAVEL</span><span
      class="s3">將嘗試接觸並與供應商聯繫以尋求協商。</span>
    </p>
    <p class="p4">
      <span class="s3"><br/></span>
    </p>
    <p class="p4">
      <span class="s3">10.6 </span><span class="s5">額外收費</span>
    </p>
    <p class="p4">
      <span class="s2"><br/></span>
    </p>
    <p class="p4">
      <span class="s2">10.6.1 </span><span class="s3">一遊</span><span class="s4"> </span><span
      class="s2">UNU TRAVEL</span><span class="s3">保留權利以為所提供的任何服務向你提出付款要求或收費。你應依照本網站的說明，支付你選擇的服務所需的所有適用的費用。</span>
    </p>
    <p class="p4">
      <span class="s3"><br/></span>
    </p>
    <p class="p4">
      <span class="s3">10.7 </span><span class="s5">收費更改</span>
    </p>
    <p class="p4">
      <span class="s2"><br/></span>
    </p>
    <p class="p4">
      <span class="s2">10.7.1 </span><span class="s3">一遊</span><span class="s4"> </span><span
      class="s2">UNU TRAVEL</span><span
      class="s3">保留隨時變更價格及收費的權利，變更的內容可能透過電子郵件或張貼在網站，若你於該等變更的通知發出後使用，或繼續使用該服務，意味閣下同意並接納變更後的價格及收。</span>
    </p>
    <p class="p4">
      <span class="s3"><br/></span>
    </p>
    <p class="p4">
      <span class="s3">10.8 </span><span class="s5">一遊</span><span class="s4"> </span><span class="s3">UNU TRAVEL</span><span
      class="s5">的權利及義務</span>
    </p>
    <p class="p4">
      <span class="s2"><br/></span>
    </p>
    <p class="p4">
      <span class="s2">10.8.1 </span><span class="s3">一遊</span><span class="s4"> </span><span
      class="s2">UNU TRAVEL</span><span class="s3">保留絕對酌情權以拒絕或取消任何可能違反此條款的服務訂單或訂購。</span>
    </p>
    <p class="p4">
      <span class="s2"><br/></span>
    </p>
    <p class="p4">
      <span class="s2">10.8.2 </span><span class="s3">一遊</span><span class="s4"> </span><span
      class="s2">UNU TRAVEL</span><span
      class="s3">致力提供或向供應商爭取以市場上的最優價值提供服務。你確認了解及認同任何於使用個別服務時所產生的稅項或額外費用，皆明確地排除在最優價格計算之外。</span>
    </p>
    <p class="p4">
      <span class="s2"><br/></span>
    </p>
    <p class="p4">
      <span class="s2">10.8.3 </span><span class="s3">儘管供應商被要求向一遊</span><span class="s4"> </span><span
      class="s2">UNU TRAVEL</span><span class="s3">網站上之服務提供準確及最新的價格，一遊</span><span class="s4"> </span><span
      class="s2">UNU TRAVEL</span><span class="s3">無法保證所提供的服務於任何時候皆準確無誤。</span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">10.9 </span><span class="s3">以上條款及細則及退款政策適用於全球的一遊</span><span class="s4"> </span><span
      class="s2">UNU TRAVEL</span><span class="s3">用戶。</span>
    </p>
    <p class="p2">
      <span class="s2"><strong><br/></strong></span>
    </p>
    <p class="p2">
      <span class="s2"><strong>11. </strong></span><span class="s3"><strong>隱私保護政策</strong></span>
    </p>
    <p class="p2">
      <span class="s2"><br/></span>
    </p>
    <p class="p2">
      <span class="s2">11.1 </span><span class="s3">隱私保護政策</span>
    </p>
    <p class="p2">
      <span class="s2"><br/></span>
    </p>
    <p class="p2">
      <span class="s2">11.1.1 </span><span class="s3">關於一遊</span><span class="s4"> </span><span
      class="s2">UNU TRAVEL</span><span
      class="s3">使用你個人資料的相關政策，請閱讀本公司現行有效的《隱私保護政策》，此等政策已納入本《使用條款》。當你接受本《使用條款》，即表示你同意《隱私保護政策》，且同意受此等政策所約束。</span>
    </p>
    <p class="p8">
      <span class="s3"><strong><br/></strong></span>
    </p>
    <p class="p8">
      <span class="s3"><strong>12. </strong></span><span class="s5"><strong>保障</strong></span>
    </p>
    <p class="p4">
      <span class="s3"><br/></span>
    </p>
    <p class="p4">
      <span class="s3">12.1 </span><span class="s5">一遊</span><span class="s4"> </span><span class="s3">UNU TRAVEL</span><span
      class="s5">保障條款</span>
    </p>
    <p class="p4">
      <span class="s2"><br/></span>
    </p>
    <p class="p4">
      <span class="s2">12.1.1 </span><span class="s3">對於你因你本人或使用你的一遊</span><span class="s4"> </span><span
      class="s2">UNU TRAVEL</span><span
      class="s3">使用者身份的任何第三方存取本網站或使用本網站、違反本《使用條款》、侵犯智慧財產權或任何人、團體之權利，而造成任何第三方提出的主張或要求，你應保障一遊</span><span
      class="s4"> </span><span class="s2">UNU TRAVEL</span><span class="s3">、一遊</span><span class="s4"> </span><span
      class="s2">UNU TRAVEL</span><span
      class="s3">的控股公司、子公司、關係企業、高階主管、董事及員工不受任何損害（包括但不限於所有損失、責任、和解、成本及律師費），如有損害，應給予賠償。</span>
    </p>
    <p class="p4">
      <span class="s2"><br/></span>
    </p>
    <p class="p4">
      <span class="s2">12.2 </span><span class="s3">一遊</span><span class="s4"> </span><span class="s2">UNU TRAVEL</span><span
      class="s3">介入</span><span class="s4"> </span><span
      class="s3">如需要，本公司得參與對任何主張或法律行動的辯護，以及任何和解協商過程。未獲得本公司事前書面許可，你不得做出任何可能不利於本公司權利或義務的和解。本公司有權自行出資，單獨對任何主張或法律行動採取辯護和控制。</span>
    </p>
    <p class="p2">
      <span class="s2"><strong><br/></strong></span>
    </p>
    <p class="p2">
      <span class="s2"><strong>13. </strong></span><span class="s3"><strong>免責聲明與責任限制</strong></span>
    </p>
    <p class="p4">
      <span class="s3"><br/></span>
    </p>
    <p class="p4">
      <span class="s3">13.1 </span><span class="s5">關係</span>
    </p>
    <p class="p4">
      <span class="s2"><br/></span>
    </p>
    <p class="p4">
      <span class="s2">13.1.1 </span><span class="s3">關於存取和使用本網站及內容，一遊</span><span class="s4"> </span><span
      class="s2">UNU TRAVEL</span><span class="s3">與你無特殊關係，且對你無受託人責任。你在此承認一遊</span><span class="s4"> </span><span
      class="s2">UNU TRAVEL</span><span class="s3">未控制且無責任對以下事項採取任何行動：（</span><span class="s2">a</span><span
      class="s3">）哪些使用者可存取本網站；（</span><span class="s2">b</span><span class="s3">）你透過本網站存取哪些內容；（</span><span
      class="s2">c</span><span class="s3">）內容對你有何影響；（</span><span class="s2">d</span><span
      class="s3">）你如何解釋或使用內容；（</span><span class="s2">e</span><span class="s3">）你因為暴露於內容而可能採取的行動。</span>
    </p>
    <p class="p4">
      <span class="s3"><br/></span>
    </p>
    <p class="p4">
      <span class="s3">13.2 </span><span class="s5">服務與說明</span>
    </p>
    <p class="p4">
      <span class="s2"><br/></span>
    </p>
    <p class="p4">
      <span class="s2">13.2.1 </span><span
      class="s3">你承認且同意本網站服務可能由其他業者或第三方提供，且其包括建議或推薦內容可能是由其他業者或本網站的使用者提供。關於服務說明是否正確，或透過本網站提供或購買的服務之建議或推薦是否適當，一遊</span><span
      class="s4"> </span><span class="s2">UNU TRAVEL</span><span class="s3">不提供任何聲明或保證。</span>
    </p>
    <p class="p4">
      <span class="s3"><br/></span>
    </p>
    <p class="p4">
      <span class="s3">13.3 </span><span class="s5">責任免除</span>
    </p>
    <p class="p4">
      <span class="s2"><br/></span>
    </p>
    <p class="p4">
      <span class="s2">13.3.1 </span><span class="s3">本網站、一遊</span><span class="s4"> </span><span
      class="s2">UNU TRAVEL</span><span class="s3">的控股公司、子公司、關係企業、高階主管、董事及</span><span class="s2">/</span><span
      class="s3">或員工在任何情況下，皆不需對以下情況造成的任何獲利損失或任何間接、必然、特殊、附帶或懲罰性的損害負責：（</span><span class="s2">a</span><span
      class="s3">）本網站；（</span><span class="s2">b</span><span class="s3">）本《使用條款》；（</span><span class="s2">c</span><span
      class="s3">）你或第三方違反本《使用條款》；（</span><span class="s2">d</span><span
      class="s3">）使用者之間任何實際或意圖的通訊或交易，即使我們已被告知有此等損害的可能性。</span>
    </p>
    <p class="p4">
      <span class="s2"><br/></span>
    </p>
    <p class="p4">
      <span class="s2">13.3.2 </span><span class="s3">不論損害是否由以下原因造成，前述之限制及排除規定均適用：（</span><span class="s2">a</span><span
      class="s3">）違反合約；（</span><span class="s2">b</span><span class="s3">）違反保證；（</span><span class="s2">c</span><span
      class="s3">）嚴格責任；（</span><span class="s2">d</span><span class="s3">）侵權；（</span><span class="s2">e</span><span
      class="s3">）疏忽；或（</span><span class="s2">f</span><span class="s3">）任何其他法律行動的原因，在適用法律允許的最大範圍內皆有效。</span>
    </p>
    <p class="p4">
      <span class="s2"><br/></span>
    </p>
    <p class="p4">
      <span class="s2">13.3.3 </span><span
      class="s3">透過本網站取得的所有內容、軟體、功能、以及資訊，皆以其「原貌」提供。在適用法律允許的最大範圍內，本公司不會明示或暗示任何形式之聲明或保證，包括但不限於本網站上的內容、軟體、透過本網站存取的資料、資訊、功能、產品、服務或對第三方的超連結，或對於違反安全行為透過本網站傳輸敏感資訊，或未侵權、可操作性、適用於特定目的或正確性，本公司未保證本網站所包含的功能或任何資料或內容不會被中斷或無錯誤。</span>
    </p>
    <p class="p4">
      <span class="s2"><br/></span>
    </p>
    <p class="p4">
      <span class="s2">13.3.4 </span><span
      class="s3">你承認且同意任何來往本網站的傳輸內容並非機密性，且你的通訊內容可被他人閱讀或截取。你進一步承認且同意，你與我們的通訊內容，以及在本網站上張貼的資訊，包括旅遊行程及</span><span
      class="s2">/</span><span class="s3">或活動，除了本《使用條款》所規定者外，你與本公司之間未建立任何機密、信託、暗示的合約或其他關係。</span>
    </p>
    <p class="p4">
      <span class="s2"><br/></span>
    </p>
    <p class="p4">
      <span class="s2">13.3.5 </span><span
      class="s3">你承認且同意，你不會要求本公司或設法讓本公司對任何其他業者或第三方提供的內容負責，而且你進一步承認且同意，本公司非其他業者或第三方提供服務之相關交易的當事人。對於本網站上任何交易、旅遊行程及／或活動的安全性，以及其他業者或第三方提供的任何列表或其他內容之真實性或正確性，本公司無任何控制權，且未提供任何保證。</span>
    </p>
    <p class="p4">
      <span class="s3"><br/></span>
    </p>
    <p class="p4">
      <span class="s3">13.4 </span><span class="s5">補救</span>
    </p>
    <p class="p4">
      <span class="s2"><br/></span>
    </p>
    <p class="p4">
      <span class="s2">13.4.1 </span><span
      class="s3">如果你對本網站不滿意、不同意本《使用條款》的任何部分，或者對本公司或本網站的其他使用者有其他與本《使用條款》或本網站有關的爭議或主張，唯一補救方法即為停止使用本網站。</span>
    </p>
    <p class="p4">
      <span class="s2"><br/></span>
    </p>
    <p class="p4">
      <span class="s2">13.4.2 </span><span class="s3">在任何情況下，且在適用法律允許的最大範圍內，本公司或一遊</span><span class="s4"> </span><span
      class="s2">UNU TRAVEL</span><span class="s3">任何會員就本網站所產生的相關事宜，對你或第三方的責任僅限於下述兩種金額之較大者：（</span><span
      class="s2">a</span><span class="s3">）</span><span class="s4"> </span><span
      class="s3">在造成責任的法律行動前十二個月您支付給我們的費用金額；或（</span><span class="s2">b</span><span class="s3">）對於所有主張累計最多</span><span
      class="s2">500.00</span><span class="s3">元港幣。</span>
    </p>
    <p class="p2">
      <span class="s2"><strong><br/></strong></span>
    </p>
    <p class="p2">
      <span class="s2"><strong>14. </strong></span><span class="s3"><strong>與第三方的互動</strong></span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">14.1 </span><span class="s3">第三方網站連結</span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">14.1.1 </span><span class="s3">本網站可能包含某些非一遊</span><span class="s4"> </span><span
      class="s2">UNU TRAVEL</span><span
      class="s3">擁有或控制的第三方網站或服務（下稱「第三方網站」）之連結，即使本網站上有前往第三方網站的連結，並不表示本公司贊同或推薦此等第三方網站，或此等第三方網站上可取得的資訊、產品、廣告或其他資料。</span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">14.1.2 </span><span
      class="s3">當你存取第三方網站時，你需自行承擔風險。你在此聲明並保證，你已閱讀與您使用服務有關的任何第三方網站的所有可適用政策，且同意受此等政策約束，除了你在本《使用條款》下的義務，你也會遵守第三方網站的所有可適用政策。</span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">14.1.3 </span><span class="s3">對於第三方網站的內容、正確性、隱私政策或實務，以及第三方網站上表達的意見，一遊</span><span
      class="s4"> </span><span class="s2">UNU TRAVEL</span><span class="s3">並無任何控制權，且不需負任何責任。此外，一遊</span><span
      class="s4"> </span><span class="s2">UNU TRAVEL</span><span
      class="s3">不會，也無法監督、證實、審查或編輯任何第三方網站的內容。若你使用一遊</span><span class="s4"> </span><span
      class="s2">UNU TRAVEL</span><span class="s3">的服務，即表示你同意免除一遊</span><span class="s4"> </span><span
      class="s2">UNU TRAVEL</span><span class="s3">對於因你使用任何第三方網站而產生的任何及所有責任。</span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">14.1.4 </span><span class="s3">若你透過一遊</span><span class="s4"> </span><span
      class="s2">UNU TRAVEL</span><span class="s3">的服務而與任何組織及</span><span class="s2">/</span><span
      class="s3">或個人有所互動（包括但不限於其他業者），包括產生商品或服務的付款和運送，以及與此等交易有關的任何其他條款、條件、保證或聲明，皆為你與此等組織及</span><span class="s2">/</span><span
      class="s3">或個人之間的關係，與本公司無涉。</span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">14.1.5 </span><span class="s3">你在與任何此等第三方從事任何線上或離線的交易之前，應進行你認為需要或適當的調查。</span>
    </p>
    <p class="p4">
      <span class="s3"><br/></span>
    </p>
    <p class="p4">
      <span class="s3">14.2 </span><span class="s5">一遊</span><span class="s4"> </span><span class="s3">UNU TRAVEL</span><span
      class="s5">的責任</span>
    </p>
    <p class="p4">
      <span class="s2"><br/></span>
    </p>
    <p class="p4">
      <span class="s2">14.2.1 </span><span class="s3">你同意一遊</span><span class="s4"> </span><span
      class="s2">UNU TRAVEL</span><span class="s3">不需為此等交易產生的任何損失或損害負責。若該網站的參與者之間或使用者與任何第三方之間發生爭議，你了解且同意一遊</span><span
      class="s4"> </span><span class="s2">UNU TRAVEL</span><span
      class="s3">無義務被牽涉其中。若你與其中一個或多個其他使用者或第三方發生爭議，你在此對一遊</span><span class="s4"> </span><span
      class="s2">UNU TRAVEL</span><span
      class="s3">、其控股公司、子公司、高階主管、董事、員工、代理人及權利繼承人免除因此等爭議而產生或相關的任何種類或性質之主張、要求及損害（實際及衍生）、已知或未知、疑似或意料外、已揭露或未揭露的主張、要求及損害。</span>
    </p>
    <p class="p8">
      <span class="s3"><strong><br/></strong></span>
    </p>
    <p class="p8">
      <span class="s3"><strong>15. </strong></span><span class="s5"><strong>付款</strong></span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">15.1 </span><span class="s3">為確保在爭議管理、退款、訂單取消（如適用）以及客戶服務方面提供全面的運營支援，以下一遊</span><span
      class="s4"> </span><span class="s2">UNU TRAVEL</span><span class="s3">授權應對下列貨幣進行的交負責：</span>
    </p>
    <p class="p3">
      <span class="s5"><br/></span>
    </p>
    <p class="p3">
      <span class="s5">以</span><span class="s3">SGD/TWD/MYR/EUR/GBP/CHF/DKK/ISK/NOK/SEK/RUB</span><span
      class="s5">付款的交易；及以其他所有貨幣付款的交易，又一科技有限公司</span><span class="s4"> </span><span
      class="s3">UNU TECHNOLOGY LIMITED</span><span class="s5">。</span>
    </p>
    <p class="p8">
      <span class="s3"><strong><br/></strong></span>
    </p>
    <p class="p8">
      <span class="s3"><strong>16. </strong></span><span class="s5"><strong>終止</strong></span>
    </p>
    <p class="p4">
      <span class="s3"><br/></span>
    </p>
    <p class="p4">
      <span class="s3">16.1 </span><span class="s5">由一遊</span><span class="s4"> </span><span
      class="s3">UNU TRAVEL</span><span class="s5">終止</span>
    </p>
    <p class="p4">
      <span class="s2"><br/></span>
    </p>
    <p class="p4">
      <span class="s2">16.1.1 </span><span class="s3">只要你使用本網站或服務，本《使用條款》即具有完全效力。一遊</span><span class="s4"> </span><span
      class="s2">UNU TRAVEL</span><span
      class="s3">得隨時基於任何理由，且無須提出任何警告，即終止或暫停你存取服務的權利或您的會員資格，與你會員資格有關的所有資訊將因此喪失或銷毀。若你違反本《使用條款》的任何條款或條件，一遊</span><span
      class="s4"> </span><span class="s2">UNU TRAVEL</span><span class="s3">也將立即終止或暫停任何及所有對你提供的服務，以及你存取本網站的權利，且一遊</span><span
      class="s4"> </span><span class="s2">UNU TRAVEL</span><span class="s3">無須發出任何事前通知，亦無須負任何責任。</span>
    </p>
    <p class="p4">
      <span class="s3"><br/></span>
    </p>
    <p class="p4">
      <span class="s3">16.2 </span><span class="s5">終止效力</span>
    </p>
    <p class="p4">
      <span class="s2"><br/></span>
    </p>
    <p class="p4">
      <span class="s2">16.2.1 </span><span
      class="s3">在你的帳戶被終止時，你使用服務、存取本網站及任何內容的權利將立即停止，但本《使用條款》中的任何規定如根據其性質，即使你的帳戶被終止後應持續生效，應於終止後存續，包括但不限於所有權規定、免保證聲明及責任限制。</span>
    </p>
    <p class="p4">
      <span class="s2"><br/></span>
    </p>
    <p class="p4">
      <span class="s2">16.2.2 </span><span class="s3">如本《使用條款》因你違反規定而終止，一遊</span><span class="s4"> </span><span
      class="s2">UNU TRAVEL</span><span class="s3">有權取消你於終止前已購買且尚未使用的憑證，而無需對你退款或負任何責任。</span>
    </p>
    <p class="p2">
      <span class="s2"><strong><br/></strong></span>
    </p>
    <p class="p2">
      <span class="s2"><strong>17. </strong></span><span class="s3"><strong>護照、簽證與保險</strong></span>
    </p>
    <p class="p4">
      <span class="s3"><br/></span>
    </p>
    <p class="p4">
      <span class="s3">17.1 </span><span class="s5">你的責任</span>
    </p>
    <p class="p4">
      <span class="s2"><br/></span>
    </p>
    <p class="p4">
      <span class="s2">17.1.1 </span><span class="s3">不論國籍和目的地為何，所有旅客應自行向其將造訪的國家之領事館詢問現行有效的入境規定。</span>
    </p>
    <p class="p4">
      <span class="s2"><br/></span>
    </p>
    <p class="p4">
      <span class="s2">17.1.2 </span><span class="s3">由於簽證及健檢的規定可能臨時變更，且無任何通知，我們建議你於旅遊前，向適當的領事館詢問健檢與簽證的相關規定。</span>
    </p>
    <p class="p4">
      <span class="s2"><br/></span>
    </p>
    <p class="p4">
      <span class="s2">17.1.3 </span><span class="s3">我們強烈建議你於出發旅遊前購買綜合旅遊保險。</span>
    </p>
    <p class="p8">
      <span class="s3"><strong><br/></strong></span>
    </p>
    <p class="p8">
      <span class="s3"><strong>18. </strong></span><span class="s5"><strong>準據法</strong></span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">18.1 </span><span class="s3">本《使用條款》應由中華人民共和國香港特別行政區的法律來規範，你同意接受香港法院的非專屬管轄權。</span>
    </p>
    <p class="p8">
      <span class="s3"><strong><br/></strong></span>
    </p>
    <p class="p8">
      <span class="s3"><strong>19. </strong></span><span class="s5"><strong>其他規定</strong></span>
    </p>
    <p class="p4">
      <span class="s3"><br/></span>
    </p>
    <p class="p4">
      <span class="s3">19.1 </span><span class="s5">可分割性</span>
    </p>
    <p class="p4">
      <span class="s2"><br/></span>
    </p>
    <p class="p4">
      <span class="s2">19.1.1 </span><span
      class="s3">如本《使用條款》的任何規定被認為無法施行或無效，該規定應在必要的最低程度內受限制或刪除，使本《使用條款》仍具有完全之效力且可施行。</span>
    </p>
    <p class="p4">
      <span class="s3"><br/></span>
    </p>
    <p class="p4">
      <span class="s3">19.2 </span><span class="s5">轉讓</span>
    </p>
    <p class="p4">
      <span class="s2"><br/></span>
    </p>
    <p class="p4">
      <span class="s2">19.2.1 </span><span class="s3">除非獲得一遊</span><span class="s4"> </span><span
      class="s2">UNU TRAVEL</span><span class="s3">的事前書面同意，否則你不得轉讓、移轉或轉授權本《使用條款》，但一遊</span><span
      class="s4"> </span><span class="s2">UNU TRAVEL</span><span class="s3">得移轉、轉讓或委託本《使用條款》及其權利與義務，而無需獲得你的同意。</span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">19.3 </span><span
      class="s3">本《使用條款》的條款及細則皆以英文編寫而成，任何其他語言的翻譯版本僅供參考。如任何翻譯版本與英文原文相異，一切皆以英文版本為準。</span>
    </p>
    <p class="p8">
      <span class="s3"><strong><br/></strong></span>
    </p>
    <p class="p8">
      <span class="s3"><strong>20. </strong></span><span class="s5"><strong>聯絡</strong></span>
    </p>
    <p class="p3">
      <span class="s2"><br/></span>
    </p>
    <p class="p3">
      <span class="s2">20.1 </span><span class="s3">如欲報告任何違反本《使用條款》之情況，或提出有關本《使用條款》或服務的疑問，請透過電子郵件（</span><span
      class="s2">support@unutravel.com</span><span class="s3">）聯絡我們。</span>
    </p>
    <p class="p5">
      <span class="s2"><br/></span>
    </p>
    <p class="p5">
      <span class="s2">2019</span><span class="s3">年</span><span class="s2">9</span><span class="s3">月</span><span
      class="s2">16</span><span class="s3">日最近一次更新</span>
    </p>
  </div>
</template>
<script>
  export default {
    name: 'TermsAndConditionsHk'
  };
</script>
