<template>
  <div>
    <form class="login-form">
      <div class="title">{{$t('registered')}}</div>
      <div class="tab-nav one cl">
        <div class="item active">
          <i class="icons i-group"/>
          <span class="label">{{$t('GroupUser')}}</span>
        </div>
      </div>
      <div class="step-tabs cl">
        <div class="item" :class="{active:cureentStep===0}">
          <span>{{$t('GroupInformation')}}</span>
        </div>
        <div class="item" :class="{active:cureentStep===1}">
          <span>{{$t('ContactInformation')}}</span>
        </div>
        <div class="item" :class="{active:cureentStep===2}">
          <span>{{$t('termsOfUse')}}</span>
        </div>
      </div>
      <div class="tabs-content">
        <transition name="slide">
          <div class="step" v-if="cureentStep===0">
            <div class="form-item">
              <div class="ipt">
                <input type="text" autocomplete="off" :placeholder="$t('Please enter your email address')"
                       v-model.trim="userAccount"/>
              </div>
            </div>
            <div class="form-item">
              <div class="ipt">
                <input class="ipt" type="password" autocomplete="off" :placeholder="$t('Please enter your password')"
                       v-model.trim="password"/>
              </div>
            </div>
            <div class="form-item">
              <div class="ipt">
                <input class="ipt" type="password" autocomplete="off"
                       :placeholder="$t('Please enter your password again')"
                       v-model.trim="confirmPassword"/>
              </div>
            </div>
            <div class="form-item">
              <div class="ipt">
                <input type="text" autocomplete="off" :placeholder="$t('Please enter verification code')"
                       v-model.trim="captcha"/>
              </div>
              <button class="btnCaptchaName" :disabled="disableCaptcha" @click="getCaptcha">{{btnCaptchaName}}</button>
            </div>
            <div class="form-item">
              <div class="ipt">
                <input type="text" autocomplete="off" :placeholder="$t('Please enter a community name')"
                       v-model.trim="groupName"/>
              </div>
            </div>
            <div class="form-item">
              <div class="ipt">
                <input type="text" autocomplete="off" :placeholder="$t('Please enter the address')"
                       v-model.trim="address"/>
              </div>
            </div>
            <div class="form-item">
              <div class="ipt">
                <input type="text" autocomplete="off" :placeholder="$t('Please enter the contact number')"
                       v-model.trim="phone"/>
              </div>
            </div>
            <a class="btn-submit" href="javascript:;" @click="onNext">{{$t('NextPage')}}</a>
          </div>
        </transition>
        <transition name="slide">
          <div class="step" v-if="cureentStep===1">
            <div class="form-item">
              <el-select class="select" v-model="gender" :placeholder="$t('Appellation')">
                <el-option v-for="item in genderOptions"
                           :key="item.id"
                           :label="item.name"
                           :value="item.code">
                </el-option>
              </el-select>
            </div>
            <div class="form-item">
              <div class="ipt">
                <input type="text" autocomplete="off" :placeholder="$t('Contact Name')" v-model.trim="userName"/>
              </div>
            </div>
            <div class="form-item">
              <div class="ipt">
                <input type="text" autocomplete="off" :placeholder="$t('Position')" v-model.trim="position"/>
              </div>
            </div>
            <div class="group cl">
              <div class="form-item fl">
                <div class="ipt">
                  <el-select class="select" v-model="areaCode" :placeholder="$t('Area code')">
                    <el-option v-for="item in areaCodeOptions"
                               :key="item.code"
                               :label="item.name"
                               :value="item.code">
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div class="form-item fr">
                <div class="ipt">
                  <input type="text" autocomplete="off" :placeholder="$t('Phone')" v-model.trim="userPhone"/>
                </div>
              </div>
            </div>
            <div class="form-item">
              <div class="ipt">
                <input class="ipt" type="text" autocomplete="off" :placeholder="$t('Email')" v-model.trim="userEmail"/>
              </div>
            </div>
            <a class="btn-submit" href="javascript:;" @click="onNext">{{$t('NextPage')}}</a>
          </div>
        </transition>
        <transition name="slide">
          <div class="step" v-if="cureentStep===2">
            <div class="terms">
              <div class="hd">{{$t('Please click on the consent and send it out after reading, that is, complete the registration')}}</div>
              <div class="bd">
                <terms-and-conditions-cn v-if="$i18n.locale==='zh-cn'"/>
                <terms-and-conditions-hk v-else/>
              </div>
              <div class="ft">
                <checkbox v-model="isAgree">
                  <div class="checkbox-label">{{$t('I have read and agree to the above terms of use')}}</div>
                </checkbox>
              </div>
            </div>
            <a class="btn-submit" href="javascript:;" @click="registered">{{$t('registered')}}</a>
          </div>
        </transition>
      </div>
      <p class="p1 txt-c">{{$t('Already have an account?')}}
        <a href="javascript:;" @click="$router.go(-1)">{{$t('Log in immediately')}}</a>
      </p>
    </form>
  </div>
</template>
<script>
  import Checkbox from '../../components/Checkbox';
  import sendAuthCode from '../../mixins/sendAuthCode';
  import {isEmail} from '../../utils/validate';
  import {getDictList} from '../../api/common';
  import {register} from '../../api/user';
  import TermsAndConditionsCn from './components/TermsAndConditionsCn';
  import TermsAndConditionsHk from './components/TermsAndConditionsHk';

  export default {
    name: 'RegisteredGroup',
    data() {
      return {
        cureentStep: 0,
        userAccount: '',
        password: '',
        confirmPassword: '',
        captcha: '',
        groupName: '',
        address: '',
        phone: '',
        // 第二步
        genderOptions: [],
        gender: '',
        userName: '',
        position: '',
        areaCodeOptions: [],
        areaCode: '',
        userPhone: '',
        userEmail: '',
        // 第三步
        isAgree: false
      };
    },
    computed: {
      disableCaptcha: function () {
        return this.duration > 0 || !isEmail(this.userAccount);
      }
    },
    components: {TermsAndConditionsHk, TermsAndConditionsCn, Checkbox},
    mixins: [sendAuthCode],
    mounted() {
      document.querySelector('html').classList.add('login-main');
    },
    created() {
      this.getDicts();
    },
    destroyed() {
      document.querySelector('html').classList.remove('login-main');
    },
    methods: {
      // 获取数据字典
      getDicts() {
        getDictList('phonePrefix').then(res => {
          const {value} = res;
          this.areaCodeOptions = value || [];
        });
        // 获取称谓选项
        getDictList('appellative').then(res => {
          this.genderOptions = res.value || [];
        });
      },
      onNext() {
        if (this.cureentStep === 0) {
          if (!isEmail(this.userAccount)) {
            this.$message.warning(this.$t('PleaseEnterTheCorrectEmail'));
            return false;
          }
          if (this.password.length < 6) {
            this.$message.warning('密碼長度至少6位');
            return false;
          }
          if (this.password !== this.confirmPassword) {
            this.$message.warning('兩次輸入的密碼不一致');
            return false;
          }
          if (!this.captcha) {
            this.$message.warning('請輸入驗證碼');
            return false;
          }
          if (!this.groupName) {
            this.$message.warning('請輸入團體名稱');
            return false;
          }
          if (!this.address) {
            this.$message.warning('請輸入地址');
            return false;
          }
          if (!this.phone) {
            this.$message.warning('請輸入聯繫電話');
            return false;
          }
        } else if (this.cureentStep === 1) {
          if (!this.gender || !this.userName || !this.position || !this.areaCode || !this.userPhone) {
            this.$message.warning('请完善聯繫人資料');
            return false;
          }
          if (!isEmail(this.userEmail)) {
            this.$message.warning(this.$t('PleaseEnterTheCorrectEmail'));
            return false;
          }
        }
        this.cureentStep += 1;
      },
      registered() {
        const data = {
          userType: 'group',
          userAccount: this.userAccount,
          password: this.password,
          captcha: this.captcha,
          groupName: this.groupName,
          address: this.address,
          phone: this.phone,
          userName: this.userName,
          isCalled: this.gender,
          position: this.position,
          phoneType: this.areaCode,
          userPhone: this.userPhone,
          userEmail: this.userEmail
        };

        if (!this.isAgree) {
          this.$message.warning('请閱讀并同意使用条款');
          return false;
        }

        const loading = this.$loading({text: 'Loading'});
        register(data).then(res => {
          loading.close();
          this.$message.success('註冊成功');
          this.$router.go(-1);
        }).catch(() => {
          loading.close();
        });
      }
    }
  };
</script>
<style scoped lang="less">
  @import "style";
  .login-form{
    width:550px;padding-bottom:30px;
    .p1.txt-c{text-align:center;}
    .group{
      margin-top:5px;
      > .fl{width:30%;}
      > .fr{width:69%;}
    }
    /deep/ .tab-nav{padding-top:20px;}
  }
  .step-tabs{
    border-bottom:1Px dashed #feb7b0;
    .item{
      float:left;width:33.3333%;text-align:center;
      span{position:relative;display:inline-block;vertical-align:top;line-height:40px;}
      &.active{
        span:after{
          position:absolute;right:0;bottom:-1Px;left:0;height:3px;background-color:#ff6f61;content:'';
        }
      }
    }
  }
  .tabs-content{
    position:relative;overflow:hidden;white-space:nowrap;
    > div{white-space:normal;}
  }
  .terms{
    .hd{margin-top:15px;}
    .bd{height:300px;margin:10px 0;padding:10px;overflow-y:auto;border:1px solid #f4f4f4;}
  }
  .slide-enter-active,
  .slide-leave-active{display:inline-block;width:100%;vertical-align:top;transform:translateZ(0);margin-left:0;transition:all .3s ease;}
  .slide-enter{margin-left:0;}
  .slide-leave-to{margin-left:-100%;}
</style>
